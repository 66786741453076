const data = [
    {
        "name": "Alphonso Mango",
        "id": 1,
        "synonyms": "Alphonso Mangoes",
        "brand": "Fresho",
        "price": 155,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Alphonso Mango",
        "id": 2,
        "synonyms": "Alphonso Mangoes",
        "brand": "Fresho",
        "price": 184,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Alphonso Mango",
        "id": 3,
        "synonyms": "Alphonso Mangoes",
        "brand": "Fresho",
        "price": 135,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Alphonso Mango - Ratnagiri",
        "id": 4,
        "synonyms": "Alphonso Mangoes - Ratnagiri",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Alphonso Mango - Ratnagiri",
        "id": 5,
        "synonyms": "Alphonso Mangoes - Ratnagiri",
        "brand": "Fresho",
        "price": 86,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Alphonso Mango - Ratnagiri",
        "id": 6,
        "synonyms": "Alphonso Mangoes - Ratnagiri",
        "brand": "Fresho",
        "price": 96,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amaranthus - Chopped",
        "id": 7,
        "synonyms": "",
        "brand": "Fresho",
        "price": 194,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amaranthus - Chopped",
        "id": 8,
        "synonyms": "",
        "brand": "Fresho",
        "price": 189,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amaranthus - Chopped",
        "id": 9,
        "synonyms": "",
        "brand": "Fresho",
        "price": 163,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amaranthus - Green",
        "id": 10,
        "synonyms": "",
        "brand": "Fresho",
        "price": 173,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amaranthus - Green",
        "id": 11,
        "synonyms": "",
        "brand": "Fresho",
        "price": 120,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amaranthus - Green",
        "id": 12,
        "synonyms": "",
        "brand": "Fresho",
        "price": 72,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amaranthus - Red",
        "id": 13,
        "synonyms": "",
        "brand": "Fresho",
        "price": 85,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amaranthus - Red",
        "id": 14,
        "synonyms": "",
        "brand": "Fresho",
        "price": 157,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amaranthus - Red",
        "id": 15,
        "synonyms": "",
        "brand": "Fresho",
        "price": 127,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amla",
        "id": 16,
        "synonyms": "",
        "brand": "Fresho",
        "price": 104,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amla",
        "id": 17,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Amla",
        "id": 18,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Fuji, Premium",
        "id": 19,
        "synonyms": "Apples - Fuji, Premium",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Fuji, Premium",
        "id": 20,
        "synonyms": "Apples - Fuji, Premium",
        "brand": "Fresho",
        "price": 162,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Fuji, Premium",
        "id": 21,
        "synonyms": "Apples - Fuji, Premium",
        "brand": "Fresho",
        "price": 56,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Fuji, Regular",
        "id": 22,
        "synonyms": "Apples - Fuji, Regular",
        "brand": "Fresho",
        "price": 121,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Fuji, Regular",
        "id": 23,
        "synonyms": "Apples - Fuji, Regular",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Fuji, Regular",
        "id": 24,
        "synonyms": "Apples - Fuji, Regular",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Green, Premium",
        "id": 25,
        "synonyms": "Apples - Green, Premium",
        "brand": "Fresho",
        "price": 166,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Green, Premium",
        "id": 26,
        "synonyms": "Apples - Green, Premium",
        "brand": "Fresho",
        "price": 128,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Green, Premium",
        "id": 27,
        "synonyms": "Apples - Green, Premium",
        "brand": "Fresho",
        "price": 51,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Kinnaur, Regular",
        "id": 28,
        "synonyms": "Apples - Kinnaur, Regular",
        "brand": "Fresho",
        "price": 54,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Kinnaur, Regular",
        "id": 29,
        "synonyms": "Apples - Kinnaur, Regular",
        "brand": "Fresho",
        "price": 89,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Kinnaur, Regular",
        "id": 30,
        "synonyms": "Apples - Kinnaur, Regular",
        "brand": "Fresho",
        "price": 90,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Modi, Premium",
        "id": 31,
        "synonyms": "Apples - Modi, Premium",
        "brand": "Fresho",
        "price": 117,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Modi, Premium",
        "id": 32,
        "synonyms": "Apples - Modi, Premium",
        "brand": "Fresho",
        "price": 87,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Modi, Premium",
        "id": 33,
        "synonyms": "Apples - Modi, Premium",
        "brand": "Fresho",
        "price": 145,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Pink Lady",
        "id": 34,
        "synonyms": "Apples - Pink Lady",
        "brand": "Fresho",
        "price": 63,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Pink Lady",
        "id": 35,
        "synonyms": "Apples - Pink Lady",
        "brand": "Fresho",
        "price": 113,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Pink Lady",
        "id": 36,
        "synonyms": "Apples - Pink Lady",
        "brand": "Fresho",
        "price": 59,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Queen, Rose, Premium",
        "id": 37,
        "synonyms": "Apples - Queen, Rose, Premium",
        "brand": "Fresho",
        "price": 92,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Queen, Rose, Premium",
        "id": 38,
        "synonyms": "Apples - Queen, Rose, Premium",
        "brand": "Fresho",
        "price": 103,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Queen, Rose, Premium",
        "id": 39,
        "synonyms": "Apples - Queen, Rose, Premium",
        "brand": "Fresho",
        "price": 54,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Red Delicious, Economy",
        "id": 40,
        "synonyms": "Apples - Red Delicious, Economy",
        "brand": "Fresho",
        "price": 174,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Red Delicious, Economy",
        "id": 41,
        "synonyms": "Apples - Red Delicious, Economy",
        "brand": "Fresho",
        "price": 150,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Red Delicious, Economy",
        "id": 42,
        "synonyms": "Apples - Red Delicious, Economy",
        "brand": "Fresho",
        "price": 100,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Red Delicious, Premium",
        "id": 43,
        "synonyms": "Apples - Red Delicious, Premium",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Red Delicious, Premium",
        "id": 44,
        "synonyms": "Apples - Red Delicious, Premium",
        "brand": "Fresho",
        "price": 176,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Red Delicious, Premium",
        "id": 45,
        "synonyms": "Apples - Red Delicious, Premium",
        "brand": "Fresho",
        "price": 104,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Red Delicious, Regular",
        "id": 46,
        "synonyms": "Apples - Red Delicious, Regular",
        "brand": "Fresho",
        "price": 71,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Red Delicious, Regular",
        "id": 47,
        "synonyms": "Apples - Red Delicious, Regular",
        "brand": "Fresho",
        "price": 113,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Red Delicious, Regular",
        "id": 48,
        "synonyms": "Apples - Red Delicious, Regular",
        "brand": "Fresho",
        "price": 56,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Royal Gala Economy",
        "id": 49,
        "synonyms": "Apples - Royal Gala Economy",
        "brand": "Fresho",
        "price": 155,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Royal Gala Economy",
        "id": 50,
        "synonyms": "Apples - Royal Gala Economy",
        "brand": "Fresho",
        "price": 110,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Royal Gala Economy",
        "id": 51,
        "synonyms": "Apples - Royal Gala Economy",
        "brand": "Fresho",
        "price": 66,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Royal Gala, Premium",
        "id": 52,
        "synonyms": "Apples - Royal Gala, Premium",
        "brand": "Fresho",
        "price": 190,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Royal Gala, Premium",
        "id": 53,
        "synonyms": "Apples - Royal Gala, Premium",
        "brand": "Fresho",
        "price": 67,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Royal Gala, Premium",
        "id": 54,
        "synonyms": "Apples - Royal Gala, Premium",
        "brand": "Fresho",
        "price": 102,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Royal Gala, Regular",
        "id": 55,
        "synonyms": "Apples - Royal Gala, Regular",
        "brand": "Fresho",
        "price": 136,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Royal Gala, Regular",
        "id": 56,
        "synonyms": "Apples - Royal Gala, Regular",
        "brand": "Fresho",
        "price": 57,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Royal Gala, Regular",
        "id": 57,
        "synonyms": "Apples - Royal Gala, Regular",
        "brand": "Fresho",
        "price": 57,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla",
        "id": 58,
        "synonyms": "Apples - Shimla",
        "brand": "Fresho",
        "price": 149,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla",
        "id": 59,
        "synonyms": "Apples - Shimla",
        "brand": "Fresho",
        "price": 92,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla",
        "id": 60,
        "synonyms": "Apples - Shimla",
        "brand": "Fresho",
        "price": 86,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla, Large",
        "id": 61,
        "synonyms": "Apples - Shimla, Large",
        "brand": "Fresho",
        "price": 93,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla, Large",
        "id": 62,
        "synonyms": "Apples - Shimla, Large",
        "brand": "Fresho",
        "price": 102,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla, Large",
        "id": 63,
        "synonyms": "Apples - Shimla, Large",
        "brand": "Fresho",
        "price": 103,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla, Medium",
        "id": 64,
        "synonyms": "Apples - Shimla, Medium",
        "brand": "Fresho",
        "price": 141,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla, Medium",
        "id": 65,
        "synonyms": "Apples - Shimla, Medium",
        "brand": "Fresho",
        "price": 142,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla, Medium",
        "id": 66,
        "synonyms": "Apples - Shimla, Medium",
        "brand": "Fresho",
        "price": 190,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla, Premium",
        "id": 67,
        "synonyms": "Apples - Shimla, Premium",
        "brand": "Fresho",
        "price": 110,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla, Premium",
        "id": 68,
        "synonyms": "Apples - Shimla, Premium",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Shimla, Premium",
        "id": 69,
        "synonyms": "Apples - Shimla, Premium",
        "brand": "Fresho",
        "price": 68,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Sliced, Granny Smith",
        "id": 70,
        "synonyms": "Apples - Sliced, Granny Smith",
        "brand": "Fresho",
        "price": 118,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Sliced, Granny Smith",
        "id": 71,
        "synonyms": "Apples - Sliced, Granny Smith",
        "brand": "Fresho",
        "price": 174,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Sliced, Granny Smith",
        "id": 72,
        "synonyms": "Apples - Sliced, Granny Smith",
        "brand": "Fresho",
        "price": 63,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Sliced, Royal Gala",
        "id": 73,
        "synonyms": "Apples - Sliced, Royal Gala",
        "brand": "Fresho",
        "price": 168,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Sliced, Royal Gala",
        "id": 74,
        "synonyms": "Apples - Sliced, Royal Gala",
        "brand": "Fresho",
        "price": 121,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple - Sliced, Royal Gala",
        "id": 75,
        "synonyms": "Apples - Sliced, Royal Gala",
        "brand": "Fresho",
        "price": 139,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple Slices - Mixed",
        "id": 76,
        "synonyms": "Apples Slice - Mixed",
        "brand": "Fresho",
        "price": 52,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple Slices - Mixed",
        "id": 77,
        "synonyms": "Apples Slice - Mixed",
        "brand": "Fresho",
        "price": 143,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apple Slices - Mixed",
        "id": 78,
        "synonyms": "Apples Slice - Mixed",
        "brand": "Fresho",
        "price": 52,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apricot",
        "id": 79,
        "synonyms": "",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apricot",
        "id": 80,
        "synonyms": "",
        "brand": "Fresho",
        "price": 162,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apricot",
        "id": 81,
        "synonyms": "",
        "brand": "Fresho",
        "price": 102,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apricot - Dried",
        "id": 82,
        "synonyms": "",
        "brand": "Fresho",
        "price": 101,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apricot - Dried",
        "id": 83,
        "synonyms": "",
        "brand": "Fresho",
        "price": 120,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Apricot - Dried",
        "id": 84,
        "synonyms": "",
        "brand": "Fresho",
        "price": 84,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ash Gourd - Cut",
        "id": 85,
        "synonyms": "",
        "brand": "Fresho",
        "price": 142,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ash Gourd - Cut",
        "id": 86,
        "synonyms": "",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ash Gourd - Cut",
        "id": 87,
        "synonyms": "",
        "brand": "Fresho",
        "price": 167,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Asparagus",
        "id": 88,
        "synonyms": "",
        "brand": "Fresho",
        "price": 77,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Asparagus",
        "id": 89,
        "synonyms": "",
        "brand": "Fresho",
        "price": 140,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Asparagus",
        "id": 90,
        "synonyms": "",
        "brand": "Fresho",
        "price": 186,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Assorted Apples Box",
        "id": 91,
        "synonyms": "",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Assorted Apples Box",
        "id": 92,
        "synonyms": "",
        "brand": "Fresho",
        "price": 84,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Assorted Apples Box",
        "id": 93,
        "synonyms": "",
        "brand": "Fresho",
        "price": 106,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Avocado",
        "id": 94,
        "synonyms": "",
        "brand": "Fresho",
        "price": 87,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Avocado",
        "id": 95,
        "synonyms": "",
        "brand": "Fresho",
        "price": 63,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Avocado",
        "id": 96,
        "synonyms": "",
        "brand": "Fresho",
        "price": 112,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Carrots",
        "id": 97,
        "synonyms": "",
        "brand": "Fresho",
        "price": 104,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Carrots",
        "id": 98,
        "synonyms": "",
        "brand": "Fresho",
        "price": 159,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Carrots",
        "id": 99,
        "synonyms": "",
        "brand": "Fresho",
        "price": 145,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Corn - Peeled",
        "id": 100,
        "synonyms": "",
        "brand": "Fresho",
        "price": 145,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Corn - Peeled",
        "id": 101,
        "synonyms": "",
        "brand": "Fresho",
        "price": 79,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Corn - Peeled",
        "id": 102,
        "synonyms": "",
        "brand": "Fresho",
        "price": 186,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Corn - Unpeeled",
        "id": 103,
        "synonyms": "",
        "brand": "Fresho",
        "price": 138,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Corn - Unpeeled",
        "id": 104,
        "synonyms": "",
        "brand": "Fresho",
        "price": 197,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Corn - Unpeeled",
        "id": 105,
        "synonyms": "",
        "brand": "Fresho",
        "price": 145,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Kiwi - Green",
        "id": 106,
        "synonyms": "",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Kiwi - Green",
        "id": 107,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Kiwi - Green",
        "id": 108,
        "synonyms": "",
        "brand": "Fresho",
        "price": 194,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Orange",
        "id": 109,
        "synonyms": "",
        "brand": "Fresho",
        "price": 78,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Orange",
        "id": 110,
        "synonyms": "",
        "brand": "Fresho",
        "price": 163,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Orange",
        "id": 111,
        "synonyms": "",
        "brand": "Fresho",
        "price": 163,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Potato",
        "id": 112,
        "synonyms": "",
        "brand": "Fresho",
        "price": 54,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Potato",
        "id": 113,
        "synonyms": "",
        "brand": "Fresho",
        "price": 59,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Potato",
        "id": 114,
        "synonyms": "",
        "brand": "Fresho",
        "price": 193,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Potato - Organic",
        "id": 115,
        "synonyms": "",
        "brand": "Fresho",
        "price": 54,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Potato - Organic",
        "id": 116,
        "synonyms": "",
        "brand": "Fresho",
        "price": 131,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Potato - Organic",
        "id": 117,
        "synonyms": "",
        "brand": "Fresho",
        "price": 78,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Spinach",
        "id": 121,
        "synonyms": "",
        "brand": "Fresho",
        "price": 116,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Spinach",
        "id": 122,
        "synonyms": "",
        "brand": "Fresho",
        "price": 169,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Baby Spinach",
        "id": 123,
        "synonyms": "",
        "brand": "Fresho",
        "price": 82,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Karpooravalli",
        "id": 124,
        "synonyms": "",
        "brand": "Fresho",
        "price": 197,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Karpooravalli",
        "id": 125,
        "synonyms": "",
        "brand": "Fresho",
        "price": 134,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Karpooravalli",
        "id": 126,
        "synonyms": "",
        "brand": "Fresho",
        "price": 175,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Nendran",
        "id": 127,
        "synonyms": "",
        "brand": "Fresho",
        "price": 94,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Nendran",
        "id": 128,
        "synonyms": "",
        "brand": "Fresho",
        "price": 126,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Nendran",
        "id": 129,
        "synonyms": "",
        "brand": "Fresho",
        "price": 192,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Poovan",
        "id": 130,
        "synonyms": "",
        "brand": "Fresho",
        "price": 57,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Poovan",
        "id": 131,
        "synonyms": "",
        "brand": "Fresho",
        "price": 162,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Poovan",
        "id": 132,
        "synonyms": "",
        "brand": "Fresho",
        "price": 177,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw Green",
        "id": 133,
        "synonyms": "",
        "brand": "Fresho",
        "price": 92,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw Green",
        "id": 134,
        "synonyms": "",
        "brand": "Fresho",
        "price": 60,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw Green",
        "id": 135,
        "synonyms": "",
        "brand": "Fresho",
        "price": 67,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw, Diced",
        "id": 136,
        "synonyms": "",
        "brand": "Fresho",
        "price": 68,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw, Diced",
        "id": 137,
        "synonyms": "",
        "brand": "Fresho",
        "price": 179,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw, Diced",
        "id": 138,
        "synonyms": "",
        "brand": "Fresho",
        "price": 51,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw, Organic",
        "id": 139,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw, Organic",
        "id": 140,
        "synonyms": "",
        "brand": "Fresho",
        "price": 57,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw, Organic",
        "id": 141,
        "synonyms": "",
        "brand": "Fresho",
        "price": 139,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw, Sliced",
        "id": 142,
        "synonyms": "",
        "brand": "Fresho",
        "price": 87,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw, Sliced",
        "id": 143,
        "synonyms": "",
        "brand": "Fresho",
        "price": 85,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Raw, Sliced",
        "id": 144,
        "synonyms": "",
        "brand": "Fresho",
        "price": 103,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Red",
        "id": 145,
        "synonyms": "",
        "brand": "Fresho",
        "price": 182,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Red",
        "id": 146,
        "synonyms": "",
        "brand": "Fresho",
        "price": 196,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Red",
        "id": 147,
        "synonyms": "",
        "brand": "Fresho",
        "price": 193,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Robusta",
        "id": 148,
        "synonyms": "",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Robusta",
        "id": 149,
        "synonyms": "",
        "brand": "Fresho",
        "price": 56,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Robusta",
        "id": 150,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Yelakki",
        "id": 151,
        "synonyms": "",
        "brand": "Fresho",
        "price": 146,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Yelakki",
        "id": 152,
        "synonyms": "",
        "brand": "Fresho",
        "price": 100,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Yelakki",
        "id": 153,
        "synonyms": "",
        "brand": "Fresho",
        "price": 105,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Yellaki, Organic",
        "id": 154,
        "synonyms": "",
        "brand": "Fresho",
        "price": 136,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Yellaki, Organic",
        "id": 155,
        "synonyms": "",
        "brand": "Fresho",
        "price": 116,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana - Yellaki, Organic",
        "id": 156,
        "synonyms": "",
        "brand": "Fresho",
        "price": 66,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Blossom - Peeled",
        "id": 157,
        "synonyms": "",
        "brand": "Fresho",
        "price": 176,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Blossom - Peeled",
        "id": 158,
        "synonyms": "",
        "brand": "Fresho",
        "price": 97,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Blossom - Peeled",
        "id": 159,
        "synonyms": "",
        "brand": "Fresho",
        "price": 85,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Flower",
        "id": 160,
        "synonyms": "",
        "brand": "Fresho",
        "price": 119,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Flower",
        "id": 161,
        "synonyms": "",
        "brand": "Fresho",
        "price": 64,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Flower",
        "id": 162,
        "synonyms": "",
        "brand": "Fresho",
        "price": 100,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Flower - Organic",
        "id": 163,
        "synonyms": "",
        "brand": "Fresho",
        "price": 125,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Flower - Organic",
        "id": 164,
        "synonyms": "",
        "brand": "Fresho",
        "price": 88,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Flower - Organic",
        "id": 165,
        "synonyms": "",
        "brand": "Fresho",
        "price": 154,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Stem",
        "id": 166,
        "synonyms": "",
        "brand": "Fresho",
        "price": 60,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Stem",
        "id": 167,
        "synonyms": "",
        "brand": "Fresho",
        "price": 169,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Stem",
        "id": 168,
        "synonyms": "",
        "brand": "Fresho",
        "price": 72,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Stem - Diced",
        "id": 169,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Stem - Diced",
        "id": 170,
        "synonyms": "",
        "brand": "Fresho",
        "price": 165,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Stem - Diced",
        "id": 171,
        "synonyms": "",
        "brand": "Fresho",
        "price": 80,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Stem - Organic",
        "id": 172,
        "synonyms": "",
        "brand": "Fresho",
        "price": 66,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Stem - Organic",
        "id": 173,
        "synonyms": "",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banana Stem - Organic",
        "id": 174,
        "synonyms": "",
        "brand": "Fresho",
        "price": 189,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banganapalli Mango",
        "id": 175,
        "synonyms": "",
        "brand": "Fresho",
        "price": 157,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banganapalli Mango",
        "id": 176,
        "synonyms": "",
        "brand": "Fresho",
        "price": 54,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Banganapalli Mango",
        "id": 177,
        "synonyms": "",
        "brand": "Fresho",
        "price": 102,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Basil - Thai, Sweet",
        "id": 178,
        "synonyms": "",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Basil - Thai, Sweet",
        "id": 179,
        "synonyms": "",
        "brand": "Fresho",
        "price": 132,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Basil - Thai, Sweet",
        "id": 180,
        "synonyms": "",
        "brand": "Fresho",
        "price": 64,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Broad",
        "id": 181,
        "synonyms": "",
        "brand": "Fresho",
        "price": 157,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Broad",
        "id": 182,
        "synonyms": "",
        "brand": "Fresho",
        "price": 137,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Broad",
        "id": 183,
        "synonyms": "",
        "brand": "Fresho",
        "price": 147,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Broad, Organic",
        "id": 184,
        "synonyms": "",
        "brand": "Fresho",
        "price": 101,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Broad, Organic",
        "id": 185,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Broad, Organic",
        "id": 186,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Cluster",
        "id": 187,
        "synonyms": "",
        "brand": "Fresho",
        "price": 126,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Cluster",
        "id": 188,
        "synonyms": "",
        "brand": "Fresho",
        "price": 167,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Cluster",
        "id": 189,
        "synonyms": "",
        "brand": "Fresho",
        "price": 162,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Cluster, Organic",
        "id": 190,
        "synonyms": "",
        "brand": "Fresho",
        "price": 182,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Cluster, Organic",
        "id": 191,
        "synonyms": "",
        "brand": "Fresho",
        "price": 97,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Cluster, Organic",
        "id": 192,
        "synonyms": "",
        "brand": "Fresho",
        "price": 70,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Cowpea",
        "id": 193,
        "synonyms": "",
        "brand": "Fresho",
        "price": 158,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Cowpea",
        "id": 194,
        "synonyms": "",
        "brand": "Fresho",
        "price": 141,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Cowpea",
        "id": 195,
        "synonyms": "",
        "brand": "Fresho",
        "price": 146,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Diced",
        "id": 196,
        "synonyms": "",
        "brand": "Fresho",
        "price": 51,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Diced",
        "id": 197,
        "synonyms": "",
        "brand": "Fresho",
        "price": 158,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Diced",
        "id": 198,
        "synonyms": "",
        "brand": "Fresho",
        "price": 164,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Double",
        "id": 199,
        "synonyms": "",
        "brand": "Fresho",
        "price": 181,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Double",
        "id": 200,
        "synonyms": "",
        "brand": "Fresho",
        "price": 142,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Double",
        "id": 201,
        "synonyms": "",
        "brand": "Fresho",
        "price": 125,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - French Ring, Organic",
        "id": 202,
        "synonyms": "",
        "brand": "Fresho",
        "price": 174,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - French Ring, Organic",
        "id": 203,
        "synonyms": "",
        "brand": "Fresho",
        "price": 91,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - French Ring, Organic",
        "id": 204,
        "synonyms": "",
        "brand": "Fresho",
        "price": 193,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - French, Ring",
        "id": 205,
        "synonyms": "",
        "brand": "Fresho",
        "price": 186,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - French, Ring",
        "id": 206,
        "synonyms": "",
        "brand": "Fresho",
        "price": 197,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - French, Ring",
        "id": 207,
        "synonyms": "",
        "brand": "Fresho",
        "price": 170,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - French, Round",
        "id": 208,
        "synonyms": "",
        "brand": "Fresho",
        "price": 118,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - French, Round",
        "id": 209,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - French, Round",
        "id": 210,
        "synonyms": "",
        "brand": "Fresho",
        "price": 108,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Haricot",
        "id": 211,
        "synonyms": "",
        "brand": "Fresho",
        "price": 167,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Haricot",
        "id": 212,
        "synonyms": "",
        "brand": "Fresho",
        "price": 127,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Haricot",
        "id": 213,
        "synonyms": "",
        "brand": "Fresho",
        "price": 74,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Julienne",
        "id": 214,
        "synonyms": "",
        "brand": "Fresho",
        "price": 158,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Julienne",
        "id": 215,
        "synonyms": "",
        "brand": "Fresho",
        "price": 77,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Julienne",
        "id": 216,
        "synonyms": "",
        "brand": "Fresho",
        "price": 97,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Moth, Sprouts",
        "id": 217,
        "synonyms": "",
        "brand": "Fresho",
        "price": 67,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Moth, Sprouts",
        "id": 218,
        "synonyms": "",
        "brand": "Fresho",
        "price": 113,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Moth, Sprouts",
        "id": 219,
        "synonyms": "",
        "brand": "Fresho",
        "price": 118,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Organic",
        "id": 220,
        "synonyms": "",
        "brand": "Fresho",
        "price": 183,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Organic",
        "id": 221,
        "synonyms": "",
        "brand": "Fresho",
        "price": 91,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans - Organic",
        "id": 222,
        "synonyms": "",
        "brand": "Fresho",
        "price": 138,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans Cluster - Diced",
        "id": 223,
        "synonyms": "",
        "brand": "Fresho",
        "price": 159,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans Cluster - Diced",
        "id": 224,
        "synonyms": "",
        "brand": "Fresho",
        "price": 138,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans Cluster - Diced",
        "id": 225,
        "synonyms": "",
        "brand": "Fresho",
        "price": 154,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans Cowpea - Diced",
        "id": 226,
        "synonyms": "",
        "brand": "Fresho",
        "price": 135,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans Cowpea - Diced",
        "id": 227,
        "synonyms": "",
        "brand": "Fresho",
        "price": 52,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beans Cowpea - Diced",
        "id": 228,
        "synonyms": "",
        "brand": "Fresho",
        "price": 199,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot",
        "id": 229,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot",
        "id": 230,
        "synonyms": "",
        "brand": "Fresho",
        "price": 182,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot",
        "id": 231,
        "synonyms": "",
        "brand": "Fresho",
        "price": 194,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot - Diced",
        "id": 232,
        "synonyms": "",
        "brand": "Fresho",
        "price": 64,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot - Diced",
        "id": 233,
        "synonyms": "",
        "brand": "Fresho",
        "price": 199,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot - Diced",
        "id": 234,
        "synonyms": "",
        "brand": "Fresho",
        "price": 185,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot - Julienne",
        "id": 235,
        "synonyms": "",
        "brand": "Fresho",
        "price": 168,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot - Julienne",
        "id": 236,
        "synonyms": "",
        "brand": "Fresho",
        "price": 72,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot - Julienne",
        "id": 237,
        "synonyms": "",
        "brand": "Fresho",
        "price": 174,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot - Organic",
        "id": 238,
        "synonyms": "",
        "brand": "Fresho",
        "price": 183,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot - Organic",
        "id": 239,
        "synonyms": "",
        "brand": "Fresho",
        "price": 129,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Beetroot - Organic",
        "id": 240,
        "synonyms": "",
        "brand": "Fresho",
        "price": 137,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd",
        "id": 241,
        "synonyms": "",
        "brand": "Fresho",
        "price": 74,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd",
        "id": 242,
        "synonyms": "",
        "brand": "Fresho",
        "price": 51,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd",
        "id": 243,
        "synonyms": "",
        "brand": "Fresho",
        "price": 197,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd - Diced",
        "id": 244,
        "synonyms": "",
        "brand": "Fresho",
        "price": 94,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd - Diced",
        "id": 245,
        "synonyms": "",
        "brand": "Fresho",
        "price": 64,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd - Diced",
        "id": 246,
        "synonyms": "",
        "brand": "Fresho",
        "price": 145,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd - Organic",
        "id": 247,
        "synonyms": "",
        "brand": "Fresho",
        "price": 109,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd - Organic",
        "id": 248,
        "synonyms": "",
        "brand": "Fresho",
        "price": 177,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd - Organic",
        "id": 249,
        "synonyms": "",
        "brand": "Fresho",
        "price": 71,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd - Sliced",
        "id": 250,
        "synonyms": "",
        "brand": "Fresho",
        "price": 58,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd - Sliced",
        "id": 251,
        "synonyms": "",
        "brand": "Fresho",
        "price": 165,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bitter Gourd - Sliced",
        "id": 252,
        "synonyms": "",
        "brand": "Fresho",
        "price": 121,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Blueberry - Imported",
        "id": 253,
        "synonyms": "",
        "brand": "Fresho",
        "price": 199,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Blueberry - Imported",
        "id": 254,
        "synonyms": "",
        "brand": "Fresho",
        "price": 129,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Blueberry - Imported",
        "id": 255,
        "synonyms": "",
        "brand": "Fresho",
        "price": 118,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Kabuli Chana",
        "id": 256,
        "synonyms": "",
        "brand": "Fresho",
        "price": 61,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Kabuli Chana",
        "id": 257,
        "synonyms": "",
        "brand": "Fresho",
        "price": 184,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Kabuli Chana",
        "id": 258,
        "synonyms": "",
        "brand": "Fresho",
        "price": 180,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Rajma",
        "id": 259,
        "synonyms": "",
        "brand": "Fresho",
        "price": 192,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Rajma",
        "id": 260,
        "synonyms": "",
        "brand": "Fresho",
        "price": 131,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Rajma",
        "id": 261,
        "synonyms": "",
        "brand": "Fresho",
        "price": 63,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Sweet Corn On Cob - Double",
        "id": 262,
        "synonyms": "",
        "brand": "Fresho",
        "price": 134,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Sweet Corn On Cob - Double",
        "id": 263,
        "synonyms": "",
        "brand": "Fresho",
        "price": 175,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Sweet Corn On Cob - Double",
        "id": 264,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Sweet Corn On Cob - Single",
        "id": 265,
        "synonyms": "",
        "brand": "Fresho",
        "price": 117,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Sweet Corn On Cob - Single",
        "id": 266,
        "synonyms": "",
        "brand": "Fresho",
        "price": 131,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Sweet Corn On Cob - Single",
        "id": 267,
        "synonyms": "",
        "brand": "Fresho",
        "price": 112,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Sweet Potato",
        "id": 268,
        "synonyms": "",
        "brand": "Fresho",
        "price": 74,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Sweet Potato",
        "id": 269,
        "synonyms": "",
        "brand": "Fresho",
        "price": 179,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Sweet Potato",
        "id": 270,
        "synonyms": "",
        "brand": "Fresho",
        "price": 72,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Tapioca",
        "id": 271,
        "synonyms": "",
        "brand": "Fresho",
        "price": 182,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Tapioca",
        "id": 272,
        "synonyms": "",
        "brand": "Fresho",
        "price": 140,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Boiled Tapioca",
        "id": 273,
        "synonyms": "",
        "brand": "Fresho",
        "price": 177,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bottle Gourd",
        "id": 274,
        "synonyms": "",
        "brand": "Fresho",
        "price": 59,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bottle Gourd",
        "id": 275,
        "synonyms": "",
        "brand": "Fresho",
        "price": 177,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bottle Gourd",
        "id": 276,
        "synonyms": "",
        "brand": "Fresho",
        "price": 155,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bottle Gourd - Diced",
        "id": 277,
        "synonyms": "",
        "brand": "Fresho",
        "price": 126,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bottle Gourd - Diced",
        "id": 278,
        "synonyms": "",
        "brand": "Fresho",
        "price": 172,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bottle Gourd - Diced",
        "id": 279,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bottle Gourd - Organic",
        "id": 280,
        "synonyms": "",
        "brand": "Fresho",
        "price": 71,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bottle Gourd - Organic",
        "id": 281,
        "synonyms": "",
        "brand": "Fresho",
        "price": 105,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Bottle Gourd - Organic",
        "id": 282,
        "synonyms": "",
        "brand": "Fresho",
        "price": 188,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Bottle Shape",
        "id": 283,
        "synonyms": "",
        "brand": "Fresho",
        "price": 77,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Bottle Shape",
        "id": 284,
        "synonyms": "",
        "brand": "Fresho",
        "price": 121,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Bottle Shape",
        "id": 285,
        "synonyms": "",
        "brand": "Fresho",
        "price": 111,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Green Long, Organic",
        "id": 286,
        "synonyms": "",
        "brand": "Fresho",
        "price": 74,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Green Long, Organic",
        "id": 287,
        "synonyms": "",
        "brand": "Fresho",
        "price": 119,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Green Long, Organic",
        "id": 288,
        "synonyms": "",
        "brand": "Fresho",
        "price": 122,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Green, Long",
        "id": 289,
        "synonyms": "",
        "brand": "Fresho",
        "price": 125,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Green, Long",
        "id": 290,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Green, Long",
        "id": 291,
        "synonyms": "",
        "brand": "Fresho",
        "price": 173,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Varikatri",
        "id": 292,
        "synonyms": "",
        "brand": "Fresho",
        "price": 120,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Varikatri",
        "id": 293,
        "synonyms": "",
        "brand": "Fresho",
        "price": 107,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Varikatri",
        "id": 294,
        "synonyms": "",
        "brand": "Fresho",
        "price": 198,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Varikatri, Organic",
        "id": 295,
        "synonyms": "",
        "brand": "Fresho",
        "price": 113,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Varikatri, Organic",
        "id": 296,
        "synonyms": "",
        "brand": "Fresho",
        "price": 129,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brinjal - Varikatri, Organic",
        "id": 297,
        "synonyms": "",
        "brand": "Fresho",
        "price": 144,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Broad Beans - Diced",
        "id": 298,
        "synonyms": "",
        "brand": "Fresho",
        "price": 52,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Broad Beans - Diced",
        "id": 299,
        "synonyms": "",
        "brand": "Fresho",
        "price": 157,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Broad Beans - Diced",
        "id": 300,
        "synonyms": "",
        "brand": "Fresho",
        "price": 139,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Broccoli",
        "id": 301,
        "synonyms": "",
        "brand": "Fresho",
        "price": 92,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Broccoli",
        "id": 302,
        "synonyms": "",
        "brand": "Fresho",
        "price": 105,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Broccoli",
        "id": 303,
        "synonyms": "",
        "brand": "Fresho",
        "price": 149,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Broccoli - Florets",
        "id": 304,
        "synonyms": "",
        "brand": "Fresho",
        "price": 193,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Broccoli - Florets",
        "id": 305,
        "synonyms": "",
        "brand": "Fresho",
        "price": 113,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Broccoli - Florets",
        "id": 306,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brussel Sprouts",
        "id": 307,
        "synonyms": "",
        "brand": "Fresho",
        "price": 154,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brussel Sprouts",
        "id": 308,
        "synonyms": "",
        "brand": "Fresho",
        "price": 187,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Brussel Sprouts",
        "id": 309,
        "synonyms": "",
        "brand": "Fresho",
        "price": 129,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cabbage",
        "id": 310,
        "synonyms": "",
        "brand": "Fresho",
        "price": 69,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cabbage",
        "id": 311,
        "synonyms": "",
        "brand": "Fresho",
        "price": 97,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cabbage",
        "id": 312,
        "synonyms": "",
        "brand": "Fresho",
        "price": 100,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cabbage - Organic",
        "id": 313,
        "synonyms": "",
        "brand": "Fresho",
        "price": 93,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cabbage - Organic",
        "id": 314,
        "synonyms": "",
        "brand": "Fresho",
        "price": 195,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cabbage - Organic",
        "id": 315,
        "synonyms": "",
        "brand": "Fresho",
        "price": 161,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cabbage - Red",
        "id": 316,
        "synonyms": "",
        "brand": "Fresho",
        "price": 135,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cabbage - Red",
        "id": 317,
        "synonyms": "",
        "brand": "Fresho",
        "price": 144,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cabbage - Red",
        "id": 318,
        "synonyms": "",
        "brand": "Fresho",
        "price": 200,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Diced",
        "id": 319,
        "synonyms": "",
        "brand": "Fresho",
        "price": 73,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Diced",
        "id": 320,
        "synonyms": "",
        "brand": "Fresho",
        "price": 177,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Diced",
        "id": 321,
        "synonyms": "",
        "brand": "Fresho",
        "price": 126,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Green",
        "id": 322,
        "synonyms": "",
        "brand": "Fresho",
        "price": 115,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Green",
        "id": 323,
        "synonyms": "",
        "brand": "Fresho",
        "price": 199,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Green",
        "id": 324,
        "synonyms": "",
        "brand": "Fresho",
        "price": 97,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Green, Organic",
        "id": 325,
        "synonyms": "",
        "brand": "Fresho",
        "price": 177,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Green, Organic",
        "id": 326,
        "synonyms": "",
        "brand": "Fresho",
        "price": 101,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Green, Organic",
        "id": 327,
        "synonyms": "",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Red",
        "id": 328,
        "synonyms": "",
        "brand": "Fresho",
        "price": 179,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Red",
        "id": 329,
        "synonyms": "",
        "brand": "Fresho",
        "price": 166,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Red",
        "id": 330,
        "synonyms": "",
        "brand": "Fresho",
        "price": 112,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Red, Organic",
        "id": 331,
        "synonyms": "",
        "brand": "Fresho",
        "price": 167,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Red, Organic",
        "id": 332,
        "synonyms": "",
        "brand": "Fresho",
        "price": 167,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Red, Organic",
        "id": 333,
        "synonyms": "",
        "brand": "Fresho",
        "price": 122,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Yellow",
        "id": 334,
        "synonyms": "",
        "brand": "Fresho",
        "price": 116,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Yellow",
        "id": 335,
        "synonyms": "",
        "brand": "Fresho",
        "price": 134,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Yellow",
        "id": 336,
        "synonyms": "",
        "brand": "Fresho",
        "price": 125,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Yellow, Organic",
        "id": 337,
        "synonyms": "",
        "brand": "Fresho",
        "price": 108,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Yellow, Organic",
        "id": 338,
        "synonyms": "",
        "brand": "Fresho",
        "price": 148,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Capsicum - Yellow, Organic",
        "id": 339,
        "synonyms": "",
        "brand": "Fresho",
        "price": 189,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Local",
        "id": 340,
        "synonyms": "",
        "brand": "Fresho",
        "price": 189,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Local",
        "id": 341,
        "synonyms": "",
        "brand": "Fresho",
        "price": 118,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Local",
        "id": 342,
        "synonyms": "",
        "brand": "Fresho",
        "price": 112,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Ooty",
        "id": 343,
        "synonyms": "",
        "brand": "Fresho",
        "price": 111,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Ooty",
        "id": 344,
        "synonyms": "",
        "brand": "Fresho",
        "price": 200,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Ooty",
        "id": 345,
        "synonyms": "",
        "brand": "Fresho",
        "price": 92,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Organic",
        "id": 346,
        "synonyms": "",
        "brand": "Fresho",
        "price": 124,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Organic",
        "id": 347,
        "synonyms": "",
        "brand": "Fresho",
        "price": 60,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Organic",
        "id": 348,
        "synonyms": "",
        "brand": "Fresho",
        "price": 115,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Sliced",
        "id": 349,
        "synonyms": "",
        "brand": "Fresho",
        "price": 80,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Sliced",
        "id": 350,
        "synonyms": "",
        "brand": "Fresho",
        "price": 119,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot - Sliced",
        "id": 351,
        "synonyms": "",
        "brand": "Fresho",
        "price": 62,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot Strips and Beans Strip",
        "id": 352,
        "synonyms": "",
        "brand": "Fresho",
        "price": 86,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot Strips and Beans Strip",
        "id": 353,
        "synonyms": "",
        "brand": "Fresho",
        "price": 129,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot Strips and Beans Strip",
        "id": 354,
        "synonyms": "",
        "brand": "Fresho",
        "price": 192,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot Strips, Aloo Strips, Green peas peeled",
        "id": 355,
        "synonyms": "",
        "brand": "Fresho",
        "price": 179,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot Strips, Aloo Strips, Green peas peeled",
        "id": 356,
        "synonyms": "",
        "brand": "Fresho",
        "price": 174,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrot Strips, Aloo Strips, Green peas peeled",
        "id": 357,
        "synonyms": "",
        "brand": "Fresho",
        "price": 56,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots - Diced",
        "id": 358,
        "synonyms": "",
        "brand": "Fresho",
        "price": 58,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots - Diced",
        "id": 359,
        "synonyms": "",
        "brand": "Fresho",
        "price": 81,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots - Diced",
        "id": 360,
        "synonyms": "",
        "brand": "Fresho",
        "price": 58,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots - Grated",
        "id": 361,
        "synonyms": "",
        "brand": "Fresho",
        "price": 77,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots - Grated",
        "id": 362,
        "synonyms": "",
        "brand": "Fresho",
        "price": 197,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots - Grated",
        "id": 363,
        "synonyms": "",
        "brand": "Fresho",
        "price": 77,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots - Julienne",
        "id": 364,
        "synonyms": "",
        "brand": "Fresho",
        "price": 137,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots - Julienne",
        "id": 365,
        "synonyms": "",
        "brand": "Fresho",
        "price": 120,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots - Julienne",
        "id": 366,
        "synonyms": "",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots and Peas",
        "id": 367,
        "synonyms": "",
        "brand": "Fresho",
        "price": 122,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots and Peas",
        "id": 368,
        "synonyms": "",
        "brand": "Fresho",
        "price": 56,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Carrots and Peas",
        "id": 369,
        "synonyms": "",
        "brand": "Fresho",
        "price": 122,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cauliflower",
        "id": 370,
        "synonyms": "",
        "brand": "Fresho",
        "price": 178,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cauliflower",
        "id": 371,
        "synonyms": "",
        "brand": "Fresho",
        "price": 168,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cauliflower",
        "id": 372,
        "synonyms": "",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cauliflower - Organic",
        "id": 373,
        "synonyms": "",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cauliflower - Organic",
        "id": 374,
        "synonyms": "",
        "brand": "Fresho",
        "price": 90,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cauliflower - Organic",
        "id": 375,
        "synonyms": "",
        "brand": "Fresho",
        "price": 195,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chana Sprouts - Dehydrated",
        "id": 376,
        "synonyms": "",
        "brand": "Fresho",
        "price": 188,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chana Sprouts - Dehydrated",
        "id": 377,
        "synonyms": "",
        "brand": "Fresho",
        "price": 168,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chana Sprouts - Dehydrated",
        "id": 378,
        "synonyms": "",
        "brand": "Fresho",
        "price": 68,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cherry",
        "id": 379,
        "synonyms": "",
        "brand": "Fresho",
        "price": 195,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cherry",
        "id": 380,
        "synonyms": "",
        "brand": "Fresho",
        "price": 71,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cherry",
        "id": 381,
        "synonyms": "",
        "brand": "Fresho",
        "price": 93,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chilli - Green, Organic",
        "id": 382,
        "synonyms": "",
        "brand": "Fresho",
        "price": 56,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chilli - Green, Organic",
        "id": 383,
        "synonyms": "",
        "brand": "Fresho",
        "price": 187,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chilli - Green, Organic",
        "id": 384,
        "synonyms": "",
        "brand": "Fresho",
        "price": 176,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chilli - Bajji, Mild",
        "id": 385,
        "synonyms": "",
        "brand": "Fresho",
        "price": 175,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chilli - Bajji, Mild",
        "id": 386,
        "synonyms": "",
        "brand": "Fresho",
        "price": 146,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chilli - Bajji, Mild",
        "id": 387,
        "synonyms": "",
        "brand": "Fresho",
        "price": 87,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chilli - Green Long, Medium",
        "id": 388,
        "synonyms": "",
        "brand": "Fresho",
        "price": 154,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chilli - Green Long, Medium",
        "id": 389,
        "synonyms": "",
        "brand": "Fresho",
        "price": 114,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chilli - Green Long, Medium",
        "id": 390,
        "synonyms": "",
        "brand": "Fresho",
        "price": 79,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chinese Cabbage",
        "id": 391,
        "synonyms": "",
        "brand": "Fresho",
        "price": 170,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chinese Cabbage",
        "id": 392,
        "synonyms": "",
        "brand": "Fresho",
        "price": 61,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chinese Cabbage",
        "id": 393,
        "synonyms": "",
        "brand": "Fresho",
        "price": 61,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chow Chow",
        "id": 394,
        "synonyms": "",
        "brand": "Fresho",
        "price": 162,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chow Chow",
        "id": 395,
        "synonyms": "",
        "brand": "Fresho",
        "price": 70,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Chow Chow",
        "id": 396,
        "synonyms": "",
        "brand": "Fresho",
        "price": 119,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia",
        "id": 397,
        "synonyms": "",
        "brand": "Fresho",
        "price": 145,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia",
        "id": 398,
        "synonyms": "",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia",
        "id": 399,
        "synonyms": "",
        "brand": "Fresho",
        "price": 71,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia - Julienne",
        "id": 400,
        "synonyms": "",
        "brand": "Fresho",
        "price": 196,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia - Julienne",
        "id": 401,
        "synonyms": "",
        "brand": "Fresho",
        "price": 97,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia - Julienne",
        "id": 402,
        "synonyms": "",
        "brand": "Fresho",
        "price": 110,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia - Organic",
        "id": 403,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia - Organic",
        "id": 404,
        "synonyms": "",
        "brand": "Fresho",
        "price": 108,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia - Organic",
        "id": 405,
        "synonyms": "",
        "brand": "Fresho",
        "price": 106,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia - Sliced",
        "id": 406,
        "synonyms": "",
        "brand": "Fresho",
        "price": 74,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia - Sliced",
        "id": 407,
        "synonyms": "",
        "brand": "Fresho",
        "price": 101,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coccinia - Sliced",
        "id": 408,
        "synonyms": "",
        "brand": "Fresho",
        "price": 70,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coconut - (For Kalash with Jatta)",
        "id": 409,
        "synonyms": "",
        "brand": "Fresho",
        "price": 174,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coconut - (For Kalash with Jatta)",
        "id": 410,
        "synonyms": "",
        "brand": "Fresho",
        "price": 50,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coconut - (For Kalash with Jatta)",
        "id": 411,
        "synonyms": "",
        "brand": "Fresho",
        "price": 75,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coconut Chunks - Single Serve",
        "id": 412,
        "synonyms": "",
        "brand": "Fresho",
        "price": 180,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coconut Chunks - Single Serve",
        "id": 413,
        "synonyms": "",
        "brand": "Fresho",
        "price": 52,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Coconut Chunks - Single Serve",
        "id": 414,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Colocasia",
        "id": 415,
        "synonyms": "",
        "brand": "Fresho",
        "price": 143,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Colocasia",
        "id": 416,
        "synonyms": "",
        "brand": "Fresho",
        "price": 90,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Colocasia",
        "id": 417,
        "synonyms": "",
        "brand": "Fresho",
        "price": 142,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Colocasia - Organic",
        "id": 418,
        "synonyms": "",
        "brand": "Fresho",
        "price": 68,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Colocasia - Organic",
        "id": 419,
        "synonyms": "",
        "brand": "Fresho",
        "price": 54,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Colocasia - Organic",
        "id": 420,
        "synonyms": "",
        "brand": "Fresho",
        "price": 137,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Contola",
        "id": 421,
        "synonyms": "",
        "brand": "Fresho",
        "price": 129,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Contola",
        "id": 422,
        "synonyms": "",
        "brand": "Fresho",
        "price": 81,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Contola",
        "id": 423,
        "synonyms": "",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cucumber - Mangalore",
        "id": 424,
        "synonyms": "",
        "brand": "Fresho",
        "price": 66,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cucumber - Mangalore",
        "id": 425,
        "synonyms": "",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cucumber - Mangalore",
        "id": 426,
        "synonyms": "",
        "brand": "Fresho",
        "price": 106,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cucumber - Sliced",
        "id": 427,
        "synonyms": "",
        "brand": "Fresho",
        "price": 189,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cucumber - Sliced",
        "id": 428,
        "synonyms": "",
        "brand": "Fresho",
        "price": 165,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cucumber - Sliced",
        "id": 429,
        "synonyms": "",
        "brand": "Fresho",
        "price": 162,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut - Fruit Salad",
        "id": 430,
        "synonyms": "",
        "brand": "Fresho",
        "price": 71,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut - Fruit Salad",
        "id": 431,
        "synonyms": "",
        "brand": "Fresho",
        "price": 184,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut - Fruit Salad",
        "id": 432,
        "synonyms": "",
        "brand": "Fresho",
        "price": 93,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut - Mushroom",
        "id": 433,
        "synonyms": "",
        "brand": "Fresho",
        "price": 96,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut - Mushroom",
        "id": 434,
        "synonyms": "",
        "brand": "Fresho",
        "price": 81,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut - Mushroom",
        "id": 435,
        "synonyms": "",
        "brand": "Fresho",
        "price": 155,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut Cauliflower, Washed",
        "id": 436,
        "synonyms": "",
        "brand": "Fresho",
        "price": 81,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut Cauliflower, Washed",
        "id": 437,
        "synonyms": "",
        "brand": "Fresho",
        "price": 55,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut Cauliflower, Washed",
        "id": 438,
        "synonyms": "",
        "brand": "Fresho",
        "price": 164,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut Indian Fruit Pack",
        "id": 439,
        "synonyms": "",
        "brand": "Fresho",
        "price": 183,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut Indian Fruit Pack",
        "id": 440,
        "synonyms": "",
        "brand": "Fresho",
        "price": 82,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Cut Indian Fruit Pack",
        "id": 441,
        "synonyms": "",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dasheri Mango",
        "id": 442,
        "synonyms": "",
        "brand": "Fresho",
        "price": 100,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dasheri Mango",
        "id": 443,
        "synonyms": "",
        "brand": "Fresho",
        "price": 116,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dasheri Mango",
        "id": 444,
        "synonyms": "",
        "brand": "Fresho",
        "price": 167,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dates - Kimia, with Seed",
        "id": 445,
        "synonyms": "",
        "brand": "Fresho",
        "price": 67,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dates - Kimia, with Seed",
        "id": 446,
        "synonyms": "",
        "brand": "Fresho",
        "price": 108,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dates - Kimia, with Seed",
        "id": 447,
        "synonyms": "",
        "brand": "Fresho",
        "price": 192,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dehydrated Sprouts - Moong Bean",
        "id": 448,
        "synonyms": "",
        "brand": "Fresho",
        "price": 80,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dehydrated Sprouts - Moong Bean",
        "id": 449,
        "synonyms": "",
        "brand": "Fresho",
        "price": 132,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dehydrated Sprouts - Moong Bean",
        "id": 450,
        "synonyms": "",
        "brand": "Fresho",
        "price": 196,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dehydrated Sprouts - Moth Bean",
        "id": 451,
        "synonyms": "",
        "brand": "Fresho",
        "price": 194,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dehydrated Sprouts - Moth Bean",
        "id": 452,
        "synonyms": "",
        "brand": "Fresho",
        "price": 103,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Dehydrated Sprouts - Moth Bean",
        "id": 453,
        "synonyms": "",
        "brand": "Fresho",
        "price": 108,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Drumstick",
        "id": 454,
        "synonyms": "",
        "brand": "Fresho",
        "price": 178,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Drumstick",
        "id": 455,
        "synonyms": "",
        "brand": "Fresho",
        "price": 154,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Drumstick",
        "id": 456,
        "synonyms": "",
        "brand": "Fresho",
        "price": 122,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Drumstick - Cut",
        "id": 457,
        "synonyms": "",
        "brand": "Fresho",
        "price": 142,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Drumstick - Cut",
        "id": 458,
        "synonyms": "",
        "brand": "Fresho",
        "price": 90,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Drumstick - Cut",
        "id": 459,
        "synonyms": "",
        "brand": "Fresho",
        "price": 138,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Drumstick - Organic",
        "id": 460,
        "synonyms": "",
        "brand": "Fresho",
        "price": 98,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Drumstick - Organic",
        "id": 461,
        "synonyms": "",
        "brand": "Fresho",
        "price": 82,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Drumstick - Organic",
        "id": 462,
        "synonyms": "",
        "brand": "Fresho",
        "price": 93,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fennel",
        "id": 463,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fennel",
        "id": 464,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fennel",
        "id": 465,
        "synonyms": "",
        "brand": "Fresho",
        "price": 176,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fresh Almonds - Green",
        "id": 466,
        "synonyms": "",
        "brand": "Fresho",
        "price": 130,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fresh Almonds - Green",
        "id": 467,
        "synonyms": "",
        "brand": "Fresho",
        "price": 136,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fresh Almonds - Green",
        "id": 468,
        "synonyms": "",
        "brand": "Fresho",
        "price": 63,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fresh Figs",
        "id": 469,
        "synonyms": "",
        "brand": "Fresho",
        "price": 175,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fresh Figs",
        "id": 470,
        "synonyms": "",
        "brand": "Fresho",
        "price": 119,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fresh Figs",
        "id": 471,
        "synonyms": "",
        "brand": "Fresho",
        "price": 169,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fresh Turmeric - Organic",
        "id": 472,
        "synonyms": "",
        "brand": "Fresho",
        "price": 168,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fresh Turmeric - Organic",
        "id": 473,
        "synonyms": "",
        "brand": "Fresho",
        "price": 187,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Fresh Turmeric - Organic",
        "id": 474,
        "synonyms": "",
        "brand": "Fresho",
        "price": 60,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Garlic",
        "id": 475,
        "synonyms": "",
        "brand": "Fresho",
        "price": 175,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Garlic",
        "id": 476,
        "synonyms": "",
        "brand": "Fresho",
        "price": 93,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Garlic",
        "id": 477,
        "synonyms": "",
        "brand": "Fresho",
        "price": 168,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Garlic - Organic",
        "id": 478,
        "synonyms": "",
        "brand": "Fresho",
        "price": 74,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Garlic - Organic",
        "id": 479,
        "synonyms": "",
        "brand": "Fresho",
        "price": 67,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Garlic - Organic",
        "id": 480,
        "synonyms": "",
        "brand": "Fresho",
        "price": 78,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Garlic - Peeled",
        "id": 481,
        "synonyms": "",
        "brand": "Fresho",
        "price": 134,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Garlic - Peeled",
        "id": 482,
        "synonyms": "",
        "brand": "Fresho",
        "price": 167,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Garlic - Peeled",
        "id": 483,
        "synonyms": "",
        "brand": "Fresho",
        "price": 175,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ginger",
        "id": 484,
        "synonyms": "",
        "brand": "Fresho",
        "price": 136,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ginger",
        "id": 485,
        "synonyms": "",
        "brand": "Fresho",
        "price": 89,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ginger",
        "id": 486,
        "synonyms": "",
        "brand": "Fresho",
        "price": 109,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ginger - Chopped",
        "id": 487,
        "synonyms": "",
        "brand": "Fresho",
        "price": 80,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ginger - Chopped",
        "id": 488,
        "synonyms": "",
        "brand": "Fresho",
        "price": 170,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ginger - Chopped",
        "id": 489,
        "synonyms": "",
        "brand": "Fresho",
        "price": 112,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ginger - Organic",
        "id": 490,
        "synonyms": "",
        "brand": "Fresho",
        "price": 191,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ginger - Organic",
        "id": 491,
        "synonyms": "",
        "brand": "Fresho",
        "price": 177,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ginger - Organic",
        "id": 492,
        "synonyms": "",
        "brand": "Fresho",
        "price": 81,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grape fruit - Imported",
        "id": 493,
        "synonyms": "",
        "brand": "Fresho",
        "price": 138,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grape fruit - Imported",
        "id": 494,
        "synonyms": "",
        "brand": "Fresho",
        "price": 100,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grape fruit - Imported",
        "id": 495,
        "synonyms": "",
        "brand": "Fresho",
        "price": 50,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grape Fruit - Indian",
        "id": 496,
        "synonyms": "",
        "brand": "Fresho",
        "price": 137,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grape Fruit - Indian",
        "id": 497,
        "synonyms": "",
        "brand": "Fresho",
        "price": 146,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grape Fruit - Indian",
        "id": 498,
        "synonyms": "",
        "brand": "Fresho",
        "price": 146,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Bangalore Blue with Seed",
        "id": 499,
        "synonyms": "",
        "brand": "Fresho",
        "price": 190,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Bangalore Blue with Seed",
        "id": 500,
        "synonyms": "",
        "brand": "Fresho",
        "price": 185,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Bangalore Blue with Seed",
        "id": 501,
        "synonyms": "",
        "brand": "Fresho",
        "price": 165,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Green Seedless",
        "id": 502,
        "synonyms": "",
        "brand": "Fresho",
        "price": 90,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Green Seedless",
        "id": 503,
        "synonyms": "",
        "brand": "Fresho",
        "price": 94,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Green Seedless",
        "id": 504,
        "synonyms": "",
        "brand": "Fresho",
        "price": 94,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Red Globe",
        "id": 505,
        "synonyms": "",
        "brand": "Fresho",
        "price": 132,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Red Globe",
        "id": 506,
        "synonyms": "",
        "brand": "Fresho",
        "price": 186,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Red Globe",
        "id": 507,
        "synonyms": "",
        "brand": "Fresho",
        "price": 90,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Red Globe Indian",
        "id": 508,
        "synonyms": "",
        "brand": "Fresho",
        "price": 115,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Red Globe Indian",
        "id": 509,
        "synonyms": "",
        "brand": "Fresho",
        "price": 179,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Red Globe Indian",
        "id": 510,
        "synonyms": "",
        "brand": "Fresho",
        "price": 67,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Thompson Seedless",
        "id": 511,
        "synonyms": "",
        "brand": "Fresho",
        "price": 172,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Thompson Seedless",
        "id": 512,
        "synonyms": "",
        "brand": "Fresho",
        "price": 164,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Grapes - Thompson Seedless",
        "id": 513,
        "synonyms": "",
        "brand": "Fresho",
        "price": 182,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Cabbage - Grated",
        "id": 514,
        "synonyms": "",
        "brand": "Fresho",
        "price": 118,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Cabbage - Grated",
        "id": 515,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Cabbage - Grated",
        "id": 516,
        "synonyms": "",
        "brand": "Fresho",
        "price": 87,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Chilly - Chopped",
        "id": 517,
        "synonyms": "",
        "brand": "Fresho",
        "price": 134,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Chilly - Chopped",
        "id": 518,
        "synonyms": "",
        "brand": "Fresho",
        "price": 62,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Chilly - Chopped",
        "id": 519,
        "synonyms": "",
        "brand": "Fresho",
        "price": 184,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Peas",
        "id": 520,
        "synonyms": "",
        "brand": "Fresho",
        "price": 106,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Peas",
        "id": 521,
        "synonyms": "",
        "brand": "Fresho",
        "price": 120,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Peas",
        "id": 522,
        "synonyms": "",
        "brand": "Fresho",
        "price": 136,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Peas - Peeled",
        "id": 523,
        "synonyms": "",
        "brand": "Fresho",
        "price": 91,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Peas - Peeled",
        "id": 524,
        "synonyms": "",
        "brand": "Fresho",
        "price": 178,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Peas - Peeled",
        "id": 525,
        "synonyms": "",
        "brand": "Fresho",
        "price": 95,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Salad",
        "id": 526,
        "synonyms": "",
        "brand": "Fresho",
        "price": 143,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Salad",
        "id": 527,
        "synonyms": "",
        "brand": "Fresho",
        "price": 87,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Green Salad",
        "id": 528,
        "synonyms": "",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Groundnut - Fresh",
        "id": 529,
        "synonyms": "",
        "brand": "Fresho",
        "price": 124,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Groundnut - Fresh",
        "id": 530,
        "synonyms": "",
        "brand": "Fresho",
        "price": 156,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Groundnut - Fresh",
        "id": 531,
        "synonyms": "",
        "brand": "Fresho",
        "price": 53,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Guava",
        "id": 532,
        "synonyms": "",
        "brand": "Fresho",
        "price": 168,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Guava",
        "id": 533,
        "synonyms": "",
        "brand": "Fresho",
        "price": 154,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Guava",
        "id": 534,
        "synonyms": "",
        "brand": "Fresho",
        "price": 176,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Himayath Mango",
        "id": 535,
        "synonyms": "",
        "brand": "Fresho",
        "price": 55,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Himayath Mango",
        "id": 536,
        "synonyms": "",
        "brand": "Fresho",
        "price": 59,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Himayath Mango",
        "id": 537,
        "synonyms": "",
        "brand": "Fresho",
        "price": 137,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Jackfruit - Peeled",
        "id": 538,
        "synonyms": "",
        "brand": "Fresho",
        "price": 128,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Jackfruit - Peeled",
        "id": 539,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Jackfruit - Peeled",
        "id": 540,
        "synonyms": "",
        "brand": "Fresho",
        "price": 139,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Jamun",
        "id": 541,
        "synonyms": "",
        "brand": "Fresho",
        "price": 71,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Jamun",
        "id": 542,
        "synonyms": "",
        "brand": "Fresho",
        "price": 178,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Jamun",
        "id": 543,
        "synonyms": "",
        "brand": "Fresho",
        "price": 101,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kalaphad Mango",
        "id": 544,
        "synonyms": "",
        "brand": "Fresho",
        "price": 162,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kalaphad Mango",
        "id": 545,
        "synonyms": "",
        "brand": "Fresho",
        "price": 95,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kalaphad Mango",
        "id": 546,
        "synonyms": "",
        "brand": "Fresho",
        "price": 163,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kale - Organic",
        "id": 547,
        "synonyms": "",
        "brand": "Fresho",
        "price": 57,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kale - Organic",
        "id": 548,
        "synonyms": "",
        "brand": "Fresho",
        "price": 176,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kale - Organic",
        "id": 549,
        "synonyms": "",
        "brand": "Fresho",
        "price": 55,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kesar Mango",
        "id": 550,
        "synonyms": "",
        "brand": "Fresho",
        "price": 172,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kesar Mango",
        "id": 551,
        "synonyms": "",
        "brand": "Fresho",
        "price": 168,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kesar Mango",
        "id": 552,
        "synonyms": "",
        "brand": "Fresho",
        "price": 178,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Dried",
        "id": 553,
        "synonyms": "",
        "brand": "Fresho",
        "price": 56,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Dried",
        "id": 554,
        "synonyms": "",
        "brand": "Fresho",
        "price": 194,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Dried",
        "id": 555,
        "synonyms": "",
        "brand": "Fresho",
        "price": 159,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Green",
        "id": 556,
        "synonyms": "",
        "brand": "Fresho",
        "price": 95,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Green",
        "id": 557,
        "synonyms": "",
        "brand": "Fresho",
        "price": 156,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Green",
        "id": 558,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Green, Jumbo",
        "id": 559,
        "synonyms": "",
        "brand": "Fresho",
        "price": 168,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Green, Jumbo",
        "id": 560,
        "synonyms": "",
        "brand": "Fresho",
        "price": 70,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Green, Jumbo",
        "id": 561,
        "synonyms": "",
        "brand": "Fresho",
        "price": 67,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Sun Gold",
        "id": 562,
        "synonyms": "",
        "brand": "Fresho",
        "price": 50,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Sun Gold",
        "id": 563,
        "synonyms": "",
        "brand": "Fresho",
        "price": 145,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Kiwi - Sun Gold",
        "id": 564,
        "synonyms": "",
        "brand": "Fresho",
        "price": 174,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Knol Khol",
        "id": 565,
        "synonyms": "",
        "brand": "Fresho",
        "price": 67,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Knol Khol",
        "id": 566,
        "synonyms": "",
        "brand": "Fresho",
        "price": 172,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Knol Khol",
        "id": 567,
        "synonyms": "",
        "brand": "Fresho",
        "price": 158,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Knol Khol - Organic",
        "id": 568,
        "synonyms": "",
        "brand": "Fresho",
        "price": 133,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Knol Khol - Organic",
        "id": 569,
        "synonyms": "",
        "brand": "Fresho",
        "price": 149,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Knol Khol - Organic",
        "id": 570,
        "synonyms": "",
        "brand": "Fresho",
        "price": 57,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ladies Finger",
        "id": 571,
        "synonyms": "",
        "brand": "Fresho",
        "price": 97,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ladies Finger",
        "id": 572,
        "synonyms": "",
        "brand": "Fresho",
        "price": 108,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ladies Finger",
        "id": 573,
        "synonyms": "",
        "brand": "Fresho",
        "price": 61,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ladies Finger - Diced",
        "id": 574,
        "synonyms": "",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ladies Finger - Diced",
        "id": 575,
        "synonyms": "",
        "brand": "Fresho",
        "price": 69,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ladies Finger - Diced",
        "id": 576,
        "synonyms": "",
        "brand": "Fresho",
        "price": 185,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ladies Finger - Organic",
        "id": 577,
        "synonyms": "",
        "brand": "Fresho",
        "price": 164,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ladies Finger - Organic",
        "id": 578,
        "synonyms": "",
        "brand": "Fresho",
        "price": 61,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ladies Finger - Organic",
        "id": 579,
        "synonyms": "",
        "brand": "Fresho",
        "price": 136,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lalbagh Mango - Sindhura",
        "id": 580,
        "synonyms": "",
        "brand": "Fresho",
        "price": 123,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lalbagh Mango - Sindhura",
        "id": 581,
        "synonyms": "",
        "brand": "Fresho",
        "price": 83,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lalbagh Mango - Sindhura",
        "id": 582,
        "synonyms": "",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Leeks",
        "id": 583,
        "synonyms": "",
        "brand": "Fresho",
        "price": 124,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Leeks",
        "id": 584,
        "synonyms": "",
        "brand": "Fresho",
        "price": 69,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Leeks",
        "id": 585,
        "synonyms": "",
        "brand": "Fresho",
        "price": 154,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lettuce - Green",
        "id": 586,
        "synonyms": "",
        "brand": "Fresho",
        "price": 126,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lettuce - Green",
        "id": 587,
        "synonyms": "",
        "brand": "Fresho",
        "price": 52,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lettuce - Green",
        "id": 588,
        "synonyms": "",
        "brand": "Fresho",
        "price": 173,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lettuce - Iceberg",
        "id": 589,
        "synonyms": "",
        "brand": "Fresho",
        "price": 94,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lettuce - Iceberg",
        "id": 590,
        "synonyms": "",
        "brand": "Fresho",
        "price": 113,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lettuce - Iceberg",
        "id": 591,
        "synonyms": "",
        "brand": "Fresho",
        "price": 107,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lettuce - Red",
        "id": 592,
        "synonyms": "",
        "brand": "Fresho",
        "price": 66,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lettuce - Red",
        "id": 593,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lettuce - Red",
        "id": 594,
        "synonyms": "",
        "brand": "Fresho",
        "price": 101,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Litchi",
        "id": 595,
        "synonyms": "",
        "brand": "Fresho",
        "price": 133,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Litchi",
        "id": 596,
        "synonyms": "",
        "brand": "Fresho",
        "price": 179,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Litchi",
        "id": 597,
        "synonyms": "",
        "brand": "Fresho",
        "price": 94,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lobia Sprouts - Dehydrated",
        "id": 598,
        "synonyms": "",
        "brand": "Fresho",
        "price": 107,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lobia Sprouts - Dehydrated",
        "id": 599,
        "synonyms": "",
        "brand": "Fresho",
        "price": 51,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Lobia Sprouts - Dehydrated",
        "id": 600,
        "synonyms": "",
        "brand": "Fresho",
        "price": 182,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Longan",
        "id": 601,
        "synonyms": "",
        "brand": "Fresho",
        "price": 67,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Longan",
        "id": 602,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Longan",
        "id": 603,
        "synonyms": "",
        "brand": "Fresho",
        "price": 130,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Malgoa Mango",
        "id": 604,
        "synonyms": "",
        "brand": "Fresho",
        "price": 186,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Malgoa Mango",
        "id": 605,
        "synonyms": "",
        "brand": "Fresho",
        "price": 140,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Malgoa Mango",
        "id": 606,
        "synonyms": "",
        "brand": "Fresho",
        "price": 145,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mallika Mango",
        "id": 607,
        "synonyms": "",
        "brand": "Fresho",
        "price": 110,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mallika Mango",
        "id": 608,
        "synonyms": "",
        "brand": "Fresho",
        "price": 156,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mallika Mango",
        "id": 609,
        "synonyms": "",
        "brand": "Fresho",
        "price": 60,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango - Aphonso, Organic",
        "id": 610,
        "synonyms": "",
        "brand": "Fresho",
        "price": 129,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango - Aphonso, Organic",
        "id": 611,
        "synonyms": "",
        "brand": "Fresho",
        "price": 107,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango - Aphonso, Organic",
        "id": 612,
        "synonyms": "",
        "brand": "Fresho",
        "price": 130,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Alphonso (Ratnagiri) - Organic",
        "id": 613,
        "synonyms": "",
        "brand": "Fresho",
        "price": 72,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Alphonso (Ratnagiri) - Organic",
        "id": 614,
        "synonyms": "",
        "brand": "Fresho",
        "price": 95,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Alphonso (Ratnagiri) - Organic",
        "id": 615,
        "synonyms": "",
        "brand": "Fresho",
        "price": 163,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Mallika - Organic",
        "id": 616,
        "synonyms": "",
        "brand": "Fresho",
        "price": 127,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Mallika - Organic",
        "id": 617,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Mallika - Organic",
        "id": 618,
        "synonyms": "",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Raspuri - Organic",
        "id": 619,
        "synonyms": "",
        "brand": "Fresho",
        "price": 177,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Raspuri - Organic",
        "id": 620,
        "synonyms": "",
        "brand": "Fresho",
        "price": 149,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Raspuri - Organic",
        "id": 621,
        "synonyms": "",
        "brand": "Fresho",
        "price": 84,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Sindura - Organic",
        "id": 622,
        "synonyms": "",
        "brand": "Fresho",
        "price": 162,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Sindura - Organic",
        "id": 623,
        "synonyms": "",
        "brand": "Fresho",
        "price": 158,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Sindura - Organic",
        "id": 624,
        "synonyms": "",
        "brand": "Fresho",
        "price": 115,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Tothapuri - Organic",
        "id": 625,
        "synonyms": "",
        "brand": "Fresho",
        "price": 121,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Tothapuri - Organic",
        "id": 626,
        "synonyms": "",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mango Tothapuri - Organic",
        "id": 627,
        "synonyms": "",
        "brand": "Fresho",
        "price": 97,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mangosteen",
        "id": 628,
        "synonyms": "",
        "brand": "Fresho",
        "price": 148,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mangosteen",
        "id": 629,
        "synonyms": "",
        "brand": "Fresho",
        "price": 124,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mangosteen",
        "id": 630,
        "synonyms": "",
        "brand": "Fresho",
        "price": 71,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Masoor Sprouts - Dehydrated",
        "id": 631,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Masoor Sprouts - Dehydrated",
        "id": 632,
        "synonyms": "",
        "brand": "Fresho",
        "price": 144,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Masoor Sprouts - Dehydrated",
        "id": 633,
        "synonyms": "",
        "brand": "Fresho",
        "price": 69,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Matar Mushroom",
        "id": 634,
        "synonyms": "",
        "brand": "Fresho",
        "price": 92,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Matar Mushroom",
        "id": 635,
        "synonyms": "",
        "brand": "Fresho",
        "price": 181,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Matar Mushroom",
        "id": 636,
        "synonyms": "",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mazafati Dates",
        "id": 637,
        "synonyms": "",
        "brand": "Fresho",
        "price": 91,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mazafati Dates",
        "id": 638,
        "synonyms": "",
        "brand": "Fresho",
        "price": 107,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mazafati Dates",
        "id": 639,
        "synonyms": "",
        "brand": "Fresho",
        "price": 75,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Methi",
        "id": 640,
        "synonyms": "",
        "brand": "Fresho",
        "price": 125,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Methi",
        "id": 641,
        "synonyms": "",
        "brand": "Fresho",
        "price": 200,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Methi",
        "id": 642,
        "synonyms": "",
        "brand": "Fresho",
        "price": 77,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Methi - Chopped",
        "id": 643,
        "synonyms": "",
        "brand": "Fresho",
        "price": 170,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Methi - Chopped",
        "id": 644,
        "synonyms": "",
        "brand": "Fresho",
        "price": 87,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Methi - Chopped",
        "id": 645,
        "synonyms": "",
        "brand": "Fresho",
        "price": 87,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Microgreen Mustard",
        "id": 646,
        "synonyms": "",
        "brand": "Fresho",
        "price": 50,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Microgreen Mustard",
        "id": 647,
        "synonyms": "",
        "brand": "Fresho",
        "price": 81,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Microgreen Mustard",
        "id": 648,
        "synonyms": "",
        "brand": "Fresho",
        "price": 64,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Microgreen Radish",
        "id": 649,
        "synonyms": "",
        "brand": "Fresho",
        "price": 123,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Microgreen Radish",
        "id": 650,
        "synonyms": "",
        "brand": "Fresho",
        "price": 135,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Microgreen Radish",
        "id": 651,
        "synonyms": "",
        "brand": "Fresho",
        "price": 195,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mint - Chopped",
        "id": 652,
        "synonyms": "",
        "brand": "Fresho",
        "price": 74,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mint - Chopped",
        "id": 653,
        "synonyms": "",
        "brand": "Fresho",
        "price": 61,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mint - Chopped",
        "id": 654,
        "synonyms": "",
        "brand": "Fresho",
        "price": 83,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mint Leaves",
        "id": 655,
        "synonyms": "",
        "brand": "Fresho",
        "price": 191,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mint Leaves",
        "id": 656,
        "synonyms": "",
        "brand": "Fresho",
        "price": 92,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mint Leaves",
        "id": 657,
        "synonyms": "",
        "brand": "Fresho",
        "price": 57,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mix Sprouts - Dehydrated",
        "id": 658,
        "synonyms": "",
        "brand": "Fresho",
        "price": 66,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mix Sprouts - Dehydrated",
        "id": 659,
        "synonyms": "",
        "brand": "Fresho",
        "price": 81,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mix Sprouts - Dehydrated",
        "id": 660,
        "synonyms": "",
        "brand": "Fresho",
        "price": 86,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mosambi",
        "id": 661,
        "synonyms": "",
        "brand": "Fresho",
        "price": 144,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mosambi",
        "id": 662,
        "synonyms": "",
        "brand": "Fresho",
        "price": 70,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mosambi",
        "id": 663,
        "synonyms": "",
        "brand": "Fresho",
        "price": 194,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mosambi - Economy",
        "id": 664,
        "synonyms": "",
        "brand": "Fresho",
        "price": 176,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mosambi - Economy",
        "id": 665,
        "synonyms": "",
        "brand": "Fresho",
        "price": 87,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mosambi - Economy",
        "id": 666,
        "synonyms": "",
        "brand": "Fresho",
        "price": 106,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mosambi - Premium",
        "id": 667,
        "synonyms": "",
        "brand": "Fresho",
        "price": 135,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mosambi - Premium",
        "id": 668,
        "synonyms": "",
        "brand": "Fresho",
        "price": 128,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mosambi - Premium",
        "id": 669,
        "synonyms": "",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mushrooms - Button",
        "id": 670,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mushrooms - Button",
        "id": 671,
        "synonyms": "",
        "brand": "Fresho",
        "price": 112,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mushrooms - Button",
        "id": 672,
        "synonyms": "",
        "brand": "Fresho",
        "price": 167,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mushrooms - Oyster",
        "id": 673,
        "synonyms": "",
        "brand": "Fresho",
        "price": 130,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mushrooms - Oyster",
        "id": 674,
        "synonyms": "",
        "brand": "Fresho",
        "price": 150,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Mushrooms - Oyster",
        "id": 675,
        "synonyms": "",
        "brand": "Fresho",
        "price": 66,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Diced",
        "id": 676,
        "synonyms": "",
        "brand": "Fresho",
        "price": 176,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Diced",
        "id": 677,
        "synonyms": "",
        "brand": "Fresho",
        "price": 136,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Diced",
        "id": 678,
        "synonyms": "",
        "brand": "Fresho",
        "price": 168,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Netted Small",
        "id": 679,
        "synonyms": "",
        "brand": "Fresho",
        "price": 110,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Netted Small",
        "id": 680,
        "synonyms": "",
        "brand": "Fresho",
        "price": 118,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Netted Small",
        "id": 681,
        "synonyms": "",
        "brand": "Fresho",
        "price": 146,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Organic",
        "id": 682,
        "synonyms": "",
        "brand": "Fresho",
        "price": 160,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Organic",
        "id": 683,
        "synonyms": "",
        "brand": "Fresho",
        "price": 145,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Organic",
        "id": 684,
        "synonyms": "",
        "brand": "Fresho",
        "price": 55,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Striped",
        "id": 685,
        "synonyms": "",
        "brand": "Fresho",
        "price": 177,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Striped",
        "id": 686,
        "synonyms": "",
        "brand": "Fresho",
        "price": 69,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Muskmelon - Striped",
        "id": 687,
        "synonyms": "",
        "brand": "Fresho",
        "price": 121,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Neelam Mango",
        "id": 688,
        "synonyms": "",
        "brand": "Fresho",
        "price": 70,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Neelam Mango",
        "id": 689,
        "synonyms": "",
        "brand": "Fresho",
        "price": 149,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Neelam Mango",
        "id": 690,
        "synonyms": "",
        "brand": "Fresho",
        "price": 196,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Neem Giloy (Amruta Balli) - Organic",
        "id": 691,
        "synonyms": "",
        "brand": "Fresho",
        "price": 122,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Neem Giloy (Amruta Balli) - Organic",
        "id": 692,
        "synonyms": "",
        "brand": "Fresho",
        "price": 135,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Neem Giloy (Amruta Balli) - Organic",
        "id": 693,
        "synonyms": "",
        "brand": "Fresho",
        "price": 200,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion",
        "id": 694,
        "synonyms": "",
        "brand": "Fresho",
        "price": 78,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion",
        "id": 695,
        "synonyms": "",
        "brand": "Fresho",
        "price": 91,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion",
        "id": 696,
        "synonyms": "",
        "brand": "Fresho",
        "price": 96,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Diced",
        "id": 697,
        "synonyms": "",
        "brand": "Fresho",
        "price": 61,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Diced",
        "id": 698,
        "synonyms": "",
        "brand": "Fresho",
        "price": 178,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Diced",
        "id": 699,
        "synonyms": "",
        "brand": "Fresho",
        "price": 94,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Organic",
        "id": 700,
        "synonyms": "",
        "brand": "Fresho",
        "price": 76,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Organic",
        "id": 701,
        "synonyms": "",
        "brand": "Fresho",
        "price": 58,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Organic",
        "id": 702,
        "synonyms": "",
        "brand": "Fresho",
        "price": 137,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Sambhar",
        "id": 703,
        "synonyms": "",
        "brand": "Fresho",
        "price": 133,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Sambhar",
        "id": 704,
        "synonyms": "",
        "brand": "Fresho",
        "price": 174,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Sambhar",
        "id": 705,
        "synonyms": "",
        "brand": "Fresho",
        "price": 112,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Thin Sliced",
        "id": 706,
        "synonyms": "",
        "brand": "Fresho",
        "price": 185,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Thin Sliced",
        "id": 707,
        "synonyms": "",
        "brand": "Fresho",
        "price": 113,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - Thin Sliced",
        "id": 708,
        "synonyms": "",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - White",
        "id": 709,
        "synonyms": "",
        "brand": "Fresho",
        "price": 87,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - White",
        "id": 710,
        "synonyms": "",
        "brand": "Fresho",
        "price": 68,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Onion - White",
        "id": 711,
        "synonyms": "",
        "brand": "Fresho",
        "price": 59,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ooty Beetroot",
        "id": 712,
        "synonyms": "",
        "brand": "Fresho",
        "price": 124,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ooty Beetroot",
        "id": 713,
        "synonyms": "",
        "brand": "Fresho",
        "price": 100,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ooty Beetroot",
        "id": 714,
        "synonyms": "",
        "brand": "Fresho",
        "price": 101,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ooty Chow Chow",
        "id": 715,
        "synonyms": "",
        "brand": "Fresho",
        "price": 89,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ooty Chow Chow",
        "id": 716,
        "synonyms": "",
        "brand": "Fresho",
        "price": 190,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ooty Chow Chow",
        "id": 717,
        "synonyms": "",
        "brand": "Fresho",
        "price": 79,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ooty Red Radish",
        "id": 718,
        "synonyms": "",
        "brand": "Fresho",
        "price": 160,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ooty Red Radish",
        "id": 719,
        "synonyms": "",
        "brand": "Fresho",
        "price": 73,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ooty Red Radish",
        "id": 720,
        "synonyms": "",
        "brand": "Fresho",
        "price": 51,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Orange - Imported, Regular",
        "id": 721,
        "synonyms": "",
        "brand": "Fresho",
        "price": 73,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Orange - Imported, Regular",
        "id": 722,
        "synonyms": "",
        "brand": "Fresho",
        "price": 93,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Orange - Imported, Regular",
        "id": 723,
        "synonyms": "",
        "brand": "Fresho",
        "price": 179,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Oregano",
        "id": 724,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Oregano",
        "id": 725,
        "synonyms": "",
        "brand": "Fresho",
        "price": 117,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Oregano",
        "id": 726,
        "synonyms": "",
        "brand": "Fresho",
        "price": 125,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pak Choi",
        "id": 727,
        "synonyms": "",
        "brand": "Fresho",
        "price": 60,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pak Choi",
        "id": 728,
        "synonyms": "",
        "brand": "Fresho",
        "price": 70,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pak Choi",
        "id": 729,
        "synonyms": "",
        "brand": "Fresho",
        "price": 90,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Palak",
        "id": 730,
        "synonyms": "",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Palak",
        "id": 731,
        "synonyms": "",
        "brand": "Fresho",
        "price": 117,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Palak",
        "id": 732,
        "synonyms": "",
        "brand": "Fresho",
        "price": 169,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Palak - Organic",
        "id": 733,
        "synonyms": "",
        "brand": "Fresho",
        "price": 70,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Palak - Organic",
        "id": 734,
        "synonyms": "",
        "brand": "Fresho",
        "price": 115,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Palak - Organic",
        "id": 735,
        "synonyms": "",
        "brand": "Fresho",
        "price": 73,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Paneer Fruit",
        "id": 736,
        "synonyms": "",
        "brand": "Fresho",
        "price": 127,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Paneer Fruit",
        "id": 737,
        "synonyms": "",
        "brand": "Fresho",
        "price": 192,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Paneer Fruit",
        "id": 738,
        "synonyms": "",
        "brand": "Fresho",
        "price": 77,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Organic",
        "id": 739,
        "synonyms": "",
        "brand": "Fresho",
        "price": 164,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Organic",
        "id": 740,
        "synonyms": "",
        "brand": "Fresho",
        "price": 180,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Organic",
        "id": 741,
        "synonyms": "",
        "brand": "Fresho",
        "price": 67,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Raw",
        "id": 742,
        "synonyms": "",
        "brand": "Fresho",
        "price": 144,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Raw",
        "id": 743,
        "synonyms": "",
        "brand": "Fresho",
        "price": 145,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Raw",
        "id": 744,
        "synonyms": "",
        "brand": "Fresho",
        "price": 150,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Ripe",
        "id": 745,
        "synonyms": "",
        "brand": "Fresho",
        "price": 105,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Ripe",
        "id": 746,
        "synonyms": "",
        "brand": "Fresho",
        "price": 124,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Ripe",
        "id": 747,
        "synonyms": "",
        "brand": "Fresho",
        "price": 103,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Semi Ripe",
        "id": 748,
        "synonyms": "",
        "brand": "Fresho",
        "price": 85,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Semi Ripe",
        "id": 749,
        "synonyms": "",
        "brand": "Fresho",
        "price": 197,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya - Semi Ripe",
        "id": 750,
        "synonyms": "",
        "brand": "Fresho",
        "price": 167,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya- Diced",
        "id": 751,
        "synonyms": "",
        "brand": "Fresho",
        "price": 130,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya- Diced",
        "id": 752,
        "synonyms": "",
        "brand": "Fresho",
        "price": 191,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Papaya- Diced",
        "id": 753,
        "synonyms": "",
        "brand": "Fresho",
        "price": 54,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Parwal",
        "id": 754,
        "synonyms": "",
        "brand": "Fresho",
        "price": 120,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Parwal",
        "id": 755,
        "synonyms": "",
        "brand": "Fresho",
        "price": 91,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Parwal",
        "id": 756,
        "synonyms": "",
        "brand": "Fresho",
        "price": 69,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Passion Fruit",
        "id": 757,
        "synonyms": "",
        "brand": "Fresho",
        "price": 140,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Passion Fruit",
        "id": 758,
        "synonyms": "",
        "brand": "Fresho",
        "price": 117,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Passion Fruit",
        "id": 759,
        "synonyms": "",
        "brand": "Fresho",
        "price": 194,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Peach - Indian",
        "id": 760,
        "synonyms": "",
        "brand": "Fresho",
        "price": 118,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Peach - Indian",
        "id": 761,
        "synonyms": "",
        "brand": "Fresho",
        "price": 165,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Peach - Indian",
        "id": 762,
        "synonyms": "",
        "brand": "Fresho",
        "price": 67,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pear - Green, Imported",
        "id": 763,
        "synonyms": "",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pear - Green, Imported",
        "id": 764,
        "synonyms": "",
        "brand": "Fresho",
        "price": 80,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pear - Green, Imported",
        "id": 765,
        "synonyms": "",
        "brand": "Fresho",
        "price": 95,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pear - Naspathi, Indian",
        "id": 766,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pear - Naspathi, Indian",
        "id": 767,
        "synonyms": "",
        "brand": "Fresho",
        "price": 184,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pear - Naspathi, Indian",
        "id": 768,
        "synonyms": "",
        "brand": "Fresho",
        "price": 115,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pear - Red, Imported",
        "id": 769,
        "synonyms": "",
        "brand": "Fresho",
        "price": 50,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pear - Red, Imported",
        "id": 770,
        "synonyms": "",
        "brand": "Fresho",
        "price": 124,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pear - Red, Imported",
        "id": 771,
        "synonyms": "",
        "brand": "Fresho",
        "price": 118,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple",
        "id": 772,
        "synonyms": "",
        "brand": "Fresho",
        "price": 178,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple",
        "id": 773,
        "synonyms": "",
        "brand": "Fresho",
        "price": 162,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple",
        "id": 774,
        "synonyms": "",
        "brand": "Fresho",
        "price": 119,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple - Chunks, Single Serve",
        "id": 775,
        "synonyms": "",
        "brand": "Fresho",
        "price": 53,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple - Chunks, Single Serve",
        "id": 776,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple - Chunks, Single Serve",
        "id": 777,
        "synonyms": "",
        "brand": "Fresho",
        "price": 74,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple - Organic",
        "id": 778,
        "synonyms": "",
        "brand": "Fresho",
        "price": 181,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple - Organic",
        "id": 779,
        "synonyms": "",
        "brand": "Fresho",
        "price": 154,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple - Organic",
        "id": 780,
        "synonyms": "",
        "brand": "Fresho",
        "price": 72,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple - Slices",
        "id": 781,
        "synonyms": "",
        "brand": "Fresho",
        "price": 197,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple - Slices",
        "id": 782,
        "synonyms": "",
        "brand": "Fresho",
        "price": 89,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pineapple - Slices",
        "id": 783,
        "synonyms": "",
        "brand": "Fresho",
        "price": 116,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Plum - Imported",
        "id": 784,
        "synonyms": "",
        "brand": "Fresho",
        "price": 146,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Plum - Imported",
        "id": 785,
        "synonyms": "",
        "brand": "Fresho",
        "price": 68,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Plum - Imported",
        "id": 786,
        "synonyms": "",
        "brand": "Fresho",
        "price": 76,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Plum - Indian",
        "id": 787,
        "synonyms": "",
        "brand": "Fresho",
        "price": 178,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Plum - Indian",
        "id": 788,
        "synonyms": "",
        "brand": "Fresho",
        "price": 178,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Plum - Indian",
        "id": 789,
        "synonyms": "",
        "brand": "Fresho",
        "price": 121,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate",
        "id": 790,
        "synonyms": "",
        "brand": "Fresho",
        "price": 149,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate",
        "id": 791,
        "synonyms": "",
        "brand": "Fresho",
        "price": 73,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate",
        "id": 792,
        "synonyms": "",
        "brand": "Fresho",
        "price": 125,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Organic",
        "id": 793,
        "synonyms": "",
        "brand": "Fresho",
        "price": 83,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Organic",
        "id": 794,
        "synonyms": "",
        "brand": "Fresho",
        "price": 155,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Organic",
        "id": 795,
        "synonyms": "",
        "brand": "Fresho",
        "price": 165,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Premium",
        "id": 796,
        "synonyms": "",
        "brand": "Fresho",
        "price": 57,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Premium",
        "id": 797,
        "synonyms": "",
        "brand": "Fresho",
        "price": 200,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Premium",
        "id": 798,
        "synonyms": "",
        "brand": "Fresho",
        "price": 170,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Single Serve, Peeled",
        "id": 799,
        "synonyms": "",
        "brand": "Fresho",
        "price": 161,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Single Serve, Peeled",
        "id": 800,
        "synonyms": "",
        "brand": "Fresho",
        "price": 81,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Single Serve, Peeled",
        "id": 801,
        "synonyms": "",
        "brand": "Fresho",
        "price": 101,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Small",
        "id": 802,
        "synonyms": "",
        "brand": "Fresho",
        "price": 200,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Small",
        "id": 803,
        "synonyms": "",
        "brand": "Fresho",
        "price": 160,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegranate - Small",
        "id": 804,
        "synonyms": "",
        "brand": "Fresho",
        "price": 133,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegrante Peeled",
        "id": 805,
        "synonyms": "",
        "brand": "Fresho",
        "price": 134,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegrante Peeled",
        "id": 806,
        "synonyms": "",
        "brand": "Fresho",
        "price": 60,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomegrante Peeled",
        "id": 807,
        "synonyms": "",
        "brand": "Fresho",
        "price": 147,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomelo - Peeled",
        "id": 808,
        "synonyms": "",
        "brand": "Fresho",
        "price": 109,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomelo - Peeled",
        "id": 809,
        "synonyms": "",
        "brand": "Fresho",
        "price": 174,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pomelo - Peeled",
        "id": 810,
        "synonyms": "",
        "brand": "Fresho",
        "price": 130,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato",
        "id": 811,
        "synonyms": "",
        "brand": "Fresho",
        "price": 163,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato",
        "id": 812,
        "synonyms": "",
        "brand": "Fresho",
        "price": 199,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato",
        "id": 813,
        "synonyms": "",
        "brand": "Fresho",
        "price": 188,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Ooty",
        "id": 814,
        "synonyms": "",
        "brand": "Fresho",
        "price": 117,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Ooty",
        "id": 815,
        "synonyms": "",
        "brand": "Fresho",
        "price": 181,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Ooty",
        "id": 816,
        "synonyms": "",
        "brand": "Fresho",
        "price": 130,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Organic",
        "id": 817,
        "synonyms": "",
        "brand": "Fresho",
        "price": 122,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Organic",
        "id": 818,
        "synonyms": "",
        "brand": "Fresho",
        "price": 129,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Organic",
        "id": 819,
        "synonyms": "",
        "brand": "Fresho",
        "price": 73,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Red",
        "id": 820,
        "synonyms": "",
        "brand": "Fresho",
        "price": 86,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Red",
        "id": 821,
        "synonyms": "",
        "brand": "Fresho",
        "price": 93,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Red",
        "id": 822,
        "synonyms": "",
        "brand": "Fresho",
        "price": 105,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Wedges",
        "id": 823,
        "synonyms": "",
        "brand": "Fresho",
        "price": 61,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Wedges",
        "id": 824,
        "synonyms": "",
        "brand": "Fresho",
        "price": 180,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Potato - Wedges",
        "id": 825,
        "synonyms": "",
        "brand": "Fresho",
        "price": 169,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin - Disco",
        "id": 826,
        "synonyms": "",
        "brand": "Fresho",
        "price": 195,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin - Disco",
        "id": 827,
        "synonyms": "",
        "brand": "Fresho",
        "price": 131,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin - Disco",
        "id": 828,
        "synonyms": "",
        "brand": "Fresho",
        "price": 158,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin - Disco, Organic",
        "id": 829,
        "synonyms": "",
        "brand": "Fresho",
        "price": 94,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin - Disco, Organic",
        "id": 830,
        "synonyms": "",
        "brand": "Fresho",
        "price": 146,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin - Disco, Organic",
        "id": 831,
        "synonyms": "",
        "brand": "Fresho",
        "price": 117,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin - Organic",
        "id": 832,
        "synonyms": "",
        "brand": "Fresho",
        "price": 63,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin - Organic",
        "id": 833,
        "synonyms": "",
        "brand": "Fresho",
        "price": 65,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin - Organic",
        "id": 834,
        "synonyms": "",
        "brand": "Fresho",
        "price": 179,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin Green - Cut",
        "id": 835,
        "synonyms": "",
        "brand": "Fresho",
        "price": 143,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin Green - Cut",
        "id": 836,
        "synonyms": "",
        "brand": "Fresho",
        "price": 134,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Pumpkin Green - Cut",
        "id": 837,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Radish - Organic",
        "id": 838,
        "synonyms": "",
        "brand": "Fresho",
        "price": 66,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Radish - Organic",
        "id": 839,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Radish - Organic",
        "id": 840,
        "synonyms": "",
        "brand": "Fresho",
        "price": 92,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Radish - Red",
        "id": 841,
        "synonyms": "",
        "brand": "Fresho",
        "price": 127,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Radish - Red",
        "id": 842,
        "synonyms": "",
        "brand": "Fresho",
        "price": 62,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Radish - Red",
        "id": 843,
        "synonyms": "",
        "brand": "Fresho",
        "price": 104,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Radish - White",
        "id": 844,
        "synonyms": "",
        "brand": "Fresho",
        "price": 164,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Radish - White",
        "id": 845,
        "synonyms": "",
        "brand": "Fresho",
        "price": 158,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Radish - White",
        "id": 846,
        "synonyms": "",
        "brand": "Fresho",
        "price": 68,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Rambutan",
        "id": 847,
        "synonyms": "",
        "brand": "Fresho",
        "price": 50,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Rambutan",
        "id": 848,
        "synonyms": "",
        "brand": "Fresho",
        "price": 189,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Rambutan",
        "id": 849,
        "synonyms": "",
        "brand": "Fresho",
        "price": 132,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Raspuri/Gola Mango",
        "id": 850,
        "synonyms": "",
        "brand": "Fresho",
        "price": 126,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Raspuri/Gola Mango",
        "id": 851,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Raspuri/Gola Mango",
        "id": 852,
        "synonyms": "",
        "brand": "Fresho",
        "price": 174,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Raw Mango",
        "id": 853,
        "synonyms": "",
        "brand": "Fresho",
        "price": 128,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Raw Mango",
        "id": 854,
        "synonyms": "",
        "brand": "Fresho",
        "price": 195,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Raw Mango",
        "id": 855,
        "synonyms": "",
        "brand": "Fresho",
        "price": 93,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Raw Mango - Diced",
        "id": 856,
        "synonyms": "",
        "brand": "Fresho",
        "price": 57,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Raw Mango - Diced",
        "id": 857,
        "synonyms": "",
        "brand": "Fresho",
        "price": 55,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Raw Mango - Diced",
        "id": 858,
        "synonyms": "",
        "brand": "Fresho",
        "price": 62,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ready to Cook - Potato French Fries",
        "id": 859,
        "synonyms": "",
        "brand": "Fresho",
        "price": 98,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ready to Cook - Potato French Fries",
        "id": 860,
        "synonyms": "",
        "brand": "Fresho",
        "price": 127,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ready to Cook - Potato French Fries",
        "id": 861,
        "synonyms": "",
        "brand": "Fresho",
        "price": 175,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Red Cabbage - Grated",
        "id": 862,
        "synonyms": "",
        "brand": "Fresho",
        "price": 164,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Red Cabbage - Grated",
        "id": 863,
        "synonyms": "",
        "brand": "Fresho",
        "price": 86,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Red Cabbage - Grated",
        "id": 864,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd",
        "id": 865,
        "synonyms": "",
        "brand": "Fresho",
        "price": 147,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd",
        "id": 866,
        "synonyms": "",
        "brand": "Fresho",
        "price": 92,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd",
        "id": 867,
        "synonyms": "",
        "brand": "Fresho",
        "price": 50,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd - Diced",
        "id": 868,
        "synonyms": "",
        "brand": "Fresho",
        "price": 140,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd - Diced",
        "id": 869,
        "synonyms": "",
        "brand": "Fresho",
        "price": 175,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd - Diced",
        "id": 870,
        "synonyms": "",
        "brand": "Fresho",
        "price": 147,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd - Organic",
        "id": 871,
        "synonyms": "",
        "brand": "Fresho",
        "price": 134,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd - Organic",
        "id": 872,
        "synonyms": "",
        "brand": "Fresho",
        "price": 51,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd - Organic",
        "id": 873,
        "synonyms": "",
        "brand": "Fresho",
        "price": 54,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd - Sliced",
        "id": 874,
        "synonyms": "",
        "brand": "Fresho",
        "price": 200,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd - Sliced",
        "id": 875,
        "synonyms": "",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Ridge Gourd - Sliced",
        "id": 876,
        "synonyms": "",
        "brand": "Fresho",
        "price": 188,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Rocket or Arugula",
        "id": 877,
        "synonyms": "",
        "brand": "Fresho",
        "price": 199,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Rocket or Arugula",
        "id": 878,
        "synonyms": "",
        "brand": "Fresho",
        "price": 66,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Rocket or Arugula",
        "id": 879,
        "synonyms": "",
        "brand": "Fresho",
        "price": 140,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Romania Mango",
        "id": 880,
        "synonyms": "",
        "brand": "Fresho",
        "price": 123,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Romania Mango",
        "id": 881,
        "synonyms": "",
        "brand": "Fresho",
        "price": 138,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Romania Mango",
        "id": 882,
        "synonyms": "",
        "brand": "Fresho",
        "price": 143,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Rosemary",
        "id": 883,
        "synonyms": "",
        "brand": "Fresho",
        "price": 132,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Rosemary",
        "id": 884,
        "synonyms": "",
        "brand": "Fresho",
        "price": 50,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Rosemary",
        "id": 885,
        "synonyms": "",
        "brand": "Fresho",
        "price": 85,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sage",
        "id": 886,
        "synonyms": "",
        "brand": "Fresho",
        "price": 172,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sage",
        "id": 887,
        "synonyms": "",
        "brand": "Fresho",
        "price": 140,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sage",
        "id": 888,
        "synonyms": "",
        "brand": "Fresho",
        "price": 82,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sambar Onion - Organic",
        "id": 889,
        "synonyms": "Sambhar Onion - Organic",
        "brand": "Fresho",
        "price": 81,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sambar Onion - Organic",
        "id": 890,
        "synonyms": "Sambhar Onion - Organic",
        "brand": "Fresho",
        "price": 84,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sambar Onion - Organic",
        "id": 891,
        "synonyms": "Sambhar Onion - Organic",
        "brand": "Fresho",
        "price": 107,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sambar Onion - Peeled",
        "id": 892,
        "synonyms": "Sambhar Onion - Peeled",
        "brand": "Fresho",
        "price": 63,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sambar Onion - Peeled",
        "id": 893,
        "synonyms": "Sambhar Onion - Peeled",
        "brand": "Fresho",
        "price": 185,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sambar Onion - Peeled",
        "id": 894,
        "synonyms": "Sambhar Onion - Peeled",
        "brand": "Fresho",
        "price": 101,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sapota",
        "id": 895,
        "synonyms": "",
        "brand": "Fresho",
        "price": 167,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sapota",
        "id": 896,
        "synonyms": "",
        "brand": "Fresho",
        "price": 147,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sapota",
        "id": 897,
        "synonyms": "",
        "brand": "Fresho",
        "price": 130,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sapota - Organic",
        "id": 898,
        "synonyms": "",
        "brand": "Fresho",
        "price": 175,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sapota - Organic",
        "id": 899,
        "synonyms": "",
        "brand": "Fresho",
        "price": 55,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sapota - Organic",
        "id": 900,
        "synonyms": "",
        "brand": "Fresho",
        "price": 100,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Snake Gourd",
        "id": 901,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Snake Gourd",
        "id": 902,
        "synonyms": "",
        "brand": "Fresho",
        "price": 144,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Snake Gourd",
        "id": 903,
        "synonyms": "",
        "brand": "Fresho",
        "price": 175,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Snake gourd - Diced",
        "id": 904,
        "synonyms": "",
        "brand": "Fresho",
        "price": 147,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Snake gourd - Diced",
        "id": 905,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Snake gourd - Diced",
        "id": 906,
        "synonyms": "",
        "brand": "Fresho",
        "price": 82,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Snake Gourd - Organic",
        "id": 907,
        "synonyms": "",
        "brand": "Fresho",
        "price": 97,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Snake Gourd - Organic",
        "id": 908,
        "synonyms": "",
        "brand": "Fresho",
        "price": 124,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Snake Gourd - Organic",
        "id": 909,
        "synonyms": "",
        "brand": "Fresho",
        "price": 173,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Spinach - Chopped",
        "id": 910,
        "synonyms": "",
        "brand": "Fresho",
        "price": 54,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Spinach - Chopped",
        "id": 911,
        "synonyms": "",
        "brand": "Fresho",
        "price": 129,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Spinach - Chopped",
        "id": 912,
        "synonyms": "",
        "brand": "Fresho",
        "price": 195,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Spring Onion",
        "id": 913,
        "synonyms": "",
        "brand": "Fresho",
        "price": 97,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Spring Onion",
        "id": 914,
        "synonyms": "",
        "brand": "Fresho",
        "price": 178,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Spring Onion",
        "id": 915,
        "synonyms": "",
        "brand": "Fresho",
        "price": 138,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Spring Onion - Chopped",
        "id": 916,
        "synonyms": "",
        "brand": "Fresho",
        "price": 114,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Spring Onion - Chopped",
        "id": 917,
        "synonyms": "",
        "brand": "Fresho",
        "price": 148,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Spring Onion - Chopped",
        "id": 918,
        "synonyms": "",
        "brand": "Fresho",
        "price": 102,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Alfalfa",
        "id": 919,
        "synonyms": "",
        "brand": "Fresho",
        "price": 122,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Alfalfa",
        "id": 920,
        "synonyms": "",
        "brand": "Fresho",
        "price": 86,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Alfalfa",
        "id": 921,
        "synonyms": "",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Channa Brown",
        "id": 922,
        "synonyms": "",
        "brand": "Fresho",
        "price": 176,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Channa Brown",
        "id": 923,
        "synonyms": "",
        "brand": "Fresho",
        "price": 169,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Channa Brown",
        "id": 924,
        "synonyms": "",
        "brand": "Fresho",
        "price": 73,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Gram Green",
        "id": 925,
        "synonyms": "",
        "brand": "Fresho",
        "price": 178,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Gram Green",
        "id": 926,
        "synonyms": "",
        "brand": "Fresho",
        "price": 81,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Gram Green",
        "id": 927,
        "synonyms": "",
        "brand": "Fresho",
        "price": 105,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Green Pea",
        "id": 928,
        "synonyms": "",
        "brand": "Fresho",
        "price": 173,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Green Pea",
        "id": 929,
        "synonyms": "",
        "brand": "Fresho",
        "price": 180,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Green Pea",
        "id": 930,
        "synonyms": "",
        "brand": "Fresho",
        "price": 189,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Horse Gram",
        "id": 931,
        "synonyms": "",
        "brand": "Fresho",
        "price": 160,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Horse Gram",
        "id": 932,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Horse Gram",
        "id": 933,
        "synonyms": "",
        "brand": "Fresho",
        "price": 72,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Methi",
        "id": 934,
        "synonyms": "",
        "brand": "Fresho",
        "price": 133,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Methi",
        "id": 935,
        "synonyms": "",
        "brand": "Fresho",
        "price": 63,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Methi",
        "id": 936,
        "synonyms": "",
        "brand": "Fresho",
        "price": 190,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Mixed Gram",
        "id": 937,
        "synonyms": "",
        "brand": "Fresho",
        "price": 101,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Mixed Gram",
        "id": 938,
        "synonyms": "",
        "brand": "Fresho",
        "price": 53,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sprouts - Mixed Gram",
        "id": 939,
        "synonyms": "",
        "brand": "Fresho",
        "price": 169,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Star Fruit",
        "id": 940,
        "synonyms": "",
        "brand": "Fresho",
        "price": 104,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Star Fruit",
        "id": 941,
        "synonyms": "",
        "brand": "Fresho",
        "price": 159,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Star Fruit",
        "id": 942,
        "synonyms": "",
        "brand": "Fresho",
        "price": 98,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sugar Baby Mango",
        "id": 943,
        "synonyms": "",
        "brand": "Fresho",
        "price": 146,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sugar Baby Mango",
        "id": 944,
        "synonyms": "",
        "brand": "Fresho",
        "price": 72,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sugar Baby Mango",
        "id": 945,
        "synonyms": "",
        "brand": "Fresho",
        "price": 173,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sugarcane",
        "id": 946,
        "synonyms": "",
        "brand": "Fresho",
        "price": 166,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sugarcane",
        "id": 947,
        "synonyms": "",
        "brand": "Fresho",
        "price": 122,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sugarcane",
        "id": 948,
        "synonyms": "",
        "brand": "Fresho",
        "price": 147,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sugarcane - Diced",
        "id": 949,
        "synonyms": "",
        "brand": "Fresho",
        "price": 194,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sugarcane - Diced",
        "id": 950,
        "synonyms": "",
        "brand": "Fresho",
        "price": 109,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sugarcane - Diced",
        "id": 951,
        "synonyms": "",
        "brand": "Fresho",
        "price": 159,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Suvarnarekha Mango",
        "id": 952,
        "synonyms": "",
        "brand": "Fresho",
        "price": 196,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Suvarnarekha Mango",
        "id": 953,
        "synonyms": "",
        "brand": "Fresho",
        "price": 156,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Suvarnarekha Mango",
        "id": 954,
        "synonyms": "",
        "brand": "Fresho",
        "price": 60,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Corn",
        "id": 955,
        "synonyms": "",
        "brand": "Fresho",
        "price": 117,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Corn",
        "id": 956,
        "synonyms": "",
        "brand": "Fresho",
        "price": 110,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Corn",
        "id": 957,
        "synonyms": "",
        "brand": "Fresho",
        "price": 156,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Corn - Pellets",
        "id": 958,
        "synonyms": "",
        "brand": "Fresho",
        "price": 121,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Corn - Pellets",
        "id": 959,
        "synonyms": "",
        "brand": "Fresho",
        "price": 113,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Corn - Pellets",
        "id": 960,
        "synonyms": "",
        "brand": "Fresho",
        "price": 200,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Corn Kernels",
        "id": 961,
        "synonyms": "",
        "brand": "Fresho",
        "price": 166,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Corn Kernels",
        "id": 962,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Corn Kernels",
        "id": 963,
        "synonyms": "",
        "brand": "Fresho",
        "price": 176,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Lime - Organic",
        "id": 964,
        "synonyms": "",
        "brand": "Fresho",
        "price": 94,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Lime - Organic",
        "id": 965,
        "synonyms": "",
        "brand": "Fresho",
        "price": 103,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Lime - Organic",
        "id": 966,
        "synonyms": "",
        "brand": "Fresho",
        "price": 120,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Potato",
        "id": 967,
        "synonyms": "",
        "brand": "Fresho",
        "price": 91,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Potato",
        "id": 968,
        "synonyms": "",
        "brand": "Fresho",
        "price": 81,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Potato",
        "id": 969,
        "synonyms": "",
        "brand": "Fresho",
        "price": 179,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Potato - Organic",
        "id": 970,
        "synonyms": "",
        "brand": "Fresho",
        "price": 74,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Potato - Organic",
        "id": 971,
        "synonyms": "",
        "brand": "Fresho",
        "price": 83,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Sweet Potato - Organic",
        "id": 972,
        "synonyms": "",
        "brand": "Fresho",
        "price": 173,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Swiss Chard - Multi Colored",
        "id": 973,
        "synonyms": "",
        "brand": "Fresho",
        "price": 195,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Swiss Chard - Multi Colored",
        "id": 974,
        "synonyms": "",
        "brand": "Fresho",
        "price": 125,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Swiss Chard - Multi Colored",
        "id": 975,
        "synonyms": "",
        "brand": "Fresho",
        "price": 173,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tamarind - Sweet",
        "id": 976,
        "synonyms": "",
        "brand": "Fresho",
        "price": 64,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tamarind - Sweet",
        "id": 977,
        "synonyms": "",
        "brand": "Fresho",
        "price": 159,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tamarind - Sweet",
        "id": 978,
        "synonyms": "",
        "brand": "Fresho",
        "price": 53,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tapioca",
        "id": 979,
        "synonyms": "",
        "brand": "Fresho",
        "price": 98,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tapioca",
        "id": 980,
        "synonyms": "",
        "brand": "Fresho",
        "price": 189,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tapioca",
        "id": 981,
        "synonyms": "",
        "brand": "Fresho",
        "price": 105,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tapioca - Organic",
        "id": 982,
        "synonyms": "",
        "brand": "Fresho",
        "price": 161,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tapioca - Organic",
        "id": 983,
        "synonyms": "",
        "brand": "Fresho",
        "price": 98,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tapioca - Organic",
        "id": 984,
        "synonyms": "",
        "brand": "Fresho",
        "price": 164,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Thai Mix",
        "id": 985,
        "synonyms": "",
        "brand": "Fresho",
        "price": 102,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Thai Mix",
        "id": 986,
        "synonyms": "",
        "brand": "Fresho",
        "price": 136,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Thai Mix",
        "id": 987,
        "synonyms": "",
        "brand": "Fresho",
        "price": 108,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Thai Sweet Basil",
        "id": 988,
        "synonyms": "",
        "brand": "Fresho",
        "price": 151,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Thai Sweet Basil",
        "id": 989,
        "synonyms": "",
        "brand": "Fresho",
        "price": 59,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Thai Sweet Basil",
        "id": 990,
        "synonyms": "",
        "brand": "Fresho",
        "price": 114,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Thyme",
        "id": 991,
        "synonyms": "",
        "brand": "Fresho",
        "price": 70,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Thyme",
        "id": 992,
        "synonyms": "",
        "brand": "Fresho",
        "price": 82,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Thyme",
        "id": 993,
        "synonyms": "",
        "brand": "Fresho",
        "price": 84,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tinda",
        "id": 994,
        "synonyms": "",
        "brand": "Fresho",
        "price": 184,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tinda",
        "id": 995,
        "synonyms": "",
        "brand": "Fresho",
        "price": 193,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tinda",
        "id": 996,
        "synonyms": "",
        "brand": "Fresho",
        "price": 144,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Cherry",
        "id": 997,
        "synonyms": "tomatoes - cherry",
        "brand": "Fresho",
        "price": 79,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Cherry",
        "id": 998,
        "synonyms": "tomatoes - cherry",
        "brand": "Fresho",
        "price": 64,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Cherry",
        "id": 999,
        "synonyms": "tomatoes - cherry",
        "brand": "Fresho",
        "price": 79,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Diced",
        "id": 1000,
        "synonyms": "tomatoes - diced",
        "brand": "Fresho",
        "price": 132,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Diced",
        "id": 1001,
        "synonyms": "tomatoes - diced",
        "brand": "Fresho",
        "price": 141,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Diced",
        "id": 1002,
        "synonyms": "tomatoes - diced",
        "brand": "Fresho",
        "price": 73,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Hybrid",
        "id": 1003,
        "synonyms": "tomatoes - hybrid",
        "brand": "Fresho",
        "price": 79,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Hybrid",
        "id": 1004,
        "synonyms": "tomatoes - hybrid",
        "brand": "Fresho",
        "price": 104,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Hybrid",
        "id": 1005,
        "synonyms": "tomatoes - hybrid",
        "brand": "Fresho",
        "price": 199,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Hybrid, Organic",
        "id": 1006,
        "synonyms": "tomatoes - hybrid, organic",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Hybrid, Organic",
        "id": 1007,
        "synonyms": "tomatoes - hybrid, organic",
        "brand": "Fresho",
        "price": 102,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Hybrid, Organic",
        "id": 1008,
        "synonyms": "tomatoes - hybrid, organic",
        "brand": "Fresho",
        "price": 136,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Local",
        "id": 1009,
        "synonyms": "tomatoes - local",
        "brand": "Fresho",
        "price": 144,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Local",
        "id": 1010,
        "synonyms": "tomatoes - local",
        "brand": "Fresho",
        "price": 192,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Local",
        "id": 1011,
        "synonyms": "tomatoes - local",
        "brand": "Fresho",
        "price": 93,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Local, Organic",
        "id": 1012,
        "synonyms": "tomatoes - local, organic",
        "brand": "Fresho",
        "price": 114,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Local, Organic",
        "id": 1013,
        "synonyms": "tomatoes - local, organic",
        "brand": "Fresho",
        "price": 117,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Tomato - Local, Organic",
        "id": 1014,
        "synonyms": "tomatoes - local, organic",
        "brand": "Fresho",
        "price": 192,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Totapuri Mango",
        "id": 1015,
        "synonyms": "",
        "brand": "Fresho",
        "price": 140,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Totapuri Mango",
        "id": 1016,
        "synonyms": "",
        "brand": "Fresho",
        "price": 88,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Totapuri Mango",
        "id": 1017,
        "synonyms": "",
        "brand": "Fresho",
        "price": 163,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Turnip",
        "id": 1018,
        "synonyms": "",
        "brand": "Fresho",
        "price": 113,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Turnip",
        "id": 1019,
        "synonyms": "",
        "brand": "Fresho",
        "price": 99,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Turnip",
        "id": 1020,
        "synonyms": "",
        "brand": "Fresho",
        "price": 112,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Watermelon - Medium",
        "id": 1021,
        "synonyms": "",
        "brand": "Fresho",
        "price": 79,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Watermelon - Medium",
        "id": 1022,
        "synonyms": "",
        "brand": "Fresho",
        "price": 153,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Watermelon - Medium",
        "id": 1023,
        "synonyms": "",
        "brand": "Fresho",
        "price": 126,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Watermelon - Organic",
        "id": 1024,
        "synonyms": "",
        "brand": "Fresho",
        "price": 190,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Watermelon - Organic",
        "id": 1025,
        "synonyms": "",
        "brand": "Fresho",
        "price": 62,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Watermelon - Organic",
        "id": 1026,
        "synonyms": "",
        "brand": "Fresho",
        "price": 113,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Watermelon - Striped, Large",
        "id": 1027,
        "synonyms": "",
        "brand": "Fresho",
        "price": 180,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Watermelon - Striped, Large",
        "id": 1028,
        "synonyms": "",
        "brand": "Fresho",
        "price": 62,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Watermelon - Striped, Large",
        "id": 1029,
        "synonyms": "",
        "brand": "Fresho",
        "price": 152,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "White Jamun - Jamrul",
        "id": 1030,
        "synonyms": "",
        "brand": "Fresho",
        "price": 105,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "White Jamun - Jamrul",
        "id": 1031,
        "synonyms": "",
        "brand": "Fresho",
        "price": 98,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "White Jamun - Jamrul",
        "id": 1032,
        "synonyms": "",
        "brand": "Fresho",
        "price": 74,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "White Radish - Sliced",
        "id": 1033,
        "synonyms": "",
        "brand": "Fresho",
        "price": 102,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "White Radish - Sliced",
        "id": 1034,
        "synonyms": "",
        "brand": "Fresho",
        "price": 192,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "White Radish - Sliced",
        "id": 1035,
        "synonyms": "",
        "brand": "Fresho",
        "price": 53,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Wood Apple",
        "id": 1036,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Wood Apple",
        "id": 1037,
        "synonyms": "",
        "brand": "Fresho",
        "price": 86,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Wood Apple",
        "id": 1038,
        "synonyms": "",
        "brand": "Fresho",
        "price": 95,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yam - Cut",
        "id": 1039,
        "synonyms": "",
        "brand": "Fresho",
        "price": 120,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yam - Cut",
        "id": 1040,
        "synonyms": "",
        "brand": "Fresho",
        "price": 171,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yam - Cut",
        "id": 1041,
        "synonyms": "",
        "brand": "Fresho",
        "price": 154,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yam - Diced",
        "id": 1042,
        "synonyms": "",
        "brand": "Fresho",
        "price": 125,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yam - Diced",
        "id": 1043,
        "synonyms": "",
        "brand": "Fresho",
        "price": 155,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yam - Diced",
        "id": 1044,
        "synonyms": "",
        "brand": "Fresho",
        "price": 133,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yam - Sliced",
        "id": 1045,
        "synonyms": "",
        "brand": "Fresho",
        "price": 156,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yam - Sliced",
        "id": 1046,
        "synonyms": "",
        "brand": "Fresho",
        "price": 117,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yam - Sliced",
        "id": 1047,
        "synonyms": "",
        "brand": "Fresho",
        "price": 145,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yellow Pumpkin - Avial Cut",
        "id": 1048,
        "synonyms": "",
        "brand": "Fresho",
        "price": 111,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yellow Pumpkin - Avial Cut",
        "id": 1049,
        "synonyms": "",
        "brand": "Fresho",
        "price": 137,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Yellow Pumpkin - Avial Cut",
        "id": 1050,
        "synonyms": "",
        "brand": "Fresho",
        "price": 105,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Zucchini - Green",
        "id": 1051,
        "synonyms": "",
        "brand": "Fresho",
        "price": 124,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Zucchini - Green",
        "id": 1052,
        "synonyms": "",
        "brand": "Fresho",
        "price": 101,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Zucchini - Green",
        "id": 1053,
        "synonyms": "",
        "brand": "Fresho",
        "price": 118,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Zucchini - Yellow",
        "id": 1054,
        "synonyms": "",
        "brand": "Fresho",
        "price": 132,
        "sizeInt": 1,
        "size": "1kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Zucchini - Yellow",
        "id": 1055,
        "synonyms": "",
        "brand": "Fresho",
        "price": 124,
        "sizeInt": 2,
        "size": "2kg",
        "unit": "kg",
        "imageUrl": ""
    },
    {
        "name": "Zucchini - Yellow",
        "id": 1056,
        "synonyms": "",
        "brand": "Fresho",
        "price": 103,
        "sizeInt": 5,
        "size": "5kg",
        "unit": "kg",
        "imageUrl": ""
    }
]

export default data;