import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { HashRouter } from "react-router-dom";
import { store } from './Store'
import App from './App';


ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <App />
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);
